import type { MenuProps } from '@mui/material';
import { Box, Divider, Menu, MenuList } from '@mui/material';
import {
  CloseIcon,
  FlipBackwardIcon,
  IntersectSquareIcon,
  PlusIcon,
  TrashIcon,
} from 'src/mui/_icons';

import { Dropdown } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

import useInboxItemActions from 'src/features/inbox-items/useInboxItemActions';

import { menuList } from './InboxItemMenu.sx';

type Props = {
  onClose?: () => void;
  onCloseFromOption?: () => void;
  inboxItem?: NewInboxItem;
  withCloseOption?: boolean;
} & MenuProps &
  PortalProps;

export default function InboxItemMenu({
  onClose,
  onCloseFromOption,
  inboxItem,
  withCloseOption = false,
  open = true,
  onExit,
  ...rest
}: Props) {
  const {
    onCreateEntry,
    onConciliation,
    onRejection,
    onRestore,
    isAllowedActions,
    isAllowedCreateBill,
    isAllowedUpdateBill,
    isAllowedRejectManyInboxItems,
    isAllowedRestoreManyInboxItems,
  } = useInboxItemActions({
    inboxItem: inboxItem,
  });

  const isRejected = inboxItem?.status === 'rejected';

  return (
    <Menu
      open={open}
      TransitionProps={{ onExited: onExit }}
      onClose={onClose}
      onClick={onClose}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      sx={{ mt: 2 }}
      {...rest}
    >
      <MenuList sx={menuList}>
        {isAllowedCreateBill && (
          <Dropdown.Option icon={<PlusIcon />} onClick={onCreateEntry}>
            Criar lançamento
          </Dropdown.Option>
        )}

        {isAllowedUpdateBill && (
          <Dropdown.Option
            icon={<IntersectSquareIcon />}
            onClick={onConciliation}
          >
            Vincular à lançamento
          </Dropdown.Option>
        )}

        {isRejected && isAllowedRestoreManyInboxItems && (
          <Dropdown.Option icon={<FlipBackwardIcon />} onClick={onRestore}>
            Restaurar
          </Dropdown.Option>
        )}

        {!isRejected && isAllowedRejectManyInboxItems && (
          <Box sx={{ pt: 1 }}>
            <Divider sx={{ mx: -2, mb: 2 }} />

            <Dropdown.Option icon={<TrashIcon />} onClick={onRejection}>
              Rejeitar
            </Dropdown.Option>
          </Box>
        )}

        {withCloseOption && isAllowedActions && (
          <Box sx={{ pt: 1 }}>
            <Divider sx={{ mx: -2, mb: 2 }} />

            <Dropdown.Option icon={<CloseIcon />} onClick={onCloseFromOption}>
              Fechar
            </Dropdown.Option>
          </Box>
        )}

        {withCloseOption && !isAllowedActions && (
          <Dropdown.Option icon={<CloseIcon />} onClick={onCloseFromOption}>
            Fechar
          </Dropdown.Option>
        )}
      </MenuList>
    </Menu>
  );
}
