import type { InboxItemSource, InboxItemStatus } from './types';

export const inboxItemSourceDictionary: Record<InboxItemSource, string> = {
  dda: 'DDA',
  email: 'E-mail',
  purchase_order: 'Formulário externo',
  upload: 'Upload manual',
  whatsapp: 'WhatsApp',
};

export const inboxItemStatusDictionary: Record<InboxItemStatus, string> = {
  pending: 'Caixa de Entrada',
  rejected: 'Rejeitados',
  accepted: 'Arquivados',
};
