import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

export function SortOffIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="Icone de ordenação desativada"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M17 4L13 8M17 4L21 8M17 4C17 4 17 8.37581 17 11.5M7 4L7 5M7 20L3 16M7 20L11 16M7 20L7 10"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 19L2 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 16.5L17 20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
