import { createContext, useCallback, useContext, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { getInboxItemMerge } from 'src/libs/finbits/Management/NewInboxItems';
import { useCompanyListener } from 'src/libs/finbits/Channels';
import { InboxItemAttachmentsStatus } from 'src/libs/finbits/Management/NewInboxItems/validations';

import { parseBillForm } from 'src/features/bills-to-pay/formValues';

import type {
  AutofillSuggestionsContextProps,
  AutofillSuggestionsProviderProps,
  CallbackResponse,
} from './types';

const AutofillSuggestionsContext = createContext<
  AutofillSuggestionsContextProps | undefined
>(undefined);

export default function AutofillSuggestionsProvider({
  children,
  inboxAttachmentsStatus: inboxAttachmentsInitialStatus,
  inboxItemId,
}: AutofillSuggestionsProviderProps) {
  const { reset } = useFormContext();
  const { companyId, organizationId } = useCompanyParams();
  const [inboxAttachmentsStatus, setInboxAttachmentsStatus] = useState(
    inboxAttachmentsInitialStatus
  );
  const [suggestionApplied, setSuggestionApplied] = useState(false);

  async function resetFormValues(callbackResponse?: CallbackResponse) {
    try {
      const mergeResponse = await getInboxItemMerge({
        organizationId,
        companyId,
        inboxItemId,
      });

      reset(parseBillForm(mergeResponse));
      callbackResponse?.(mergeResponse);
      setSuggestionApplied(true);
    } catch (error) {
      console.error(`Error to get merge: ${error}`, {
        organizationId,
        companyId,
        inboxItemId,
      });
    }
  }

  const inboxOcrListener = useCallback(
    ({ inboxItemId: responseInboxItemId }: { inboxItemId: string }) => {
      if (responseInboxItemId === inboxItemId) {
        setInboxAttachmentsStatus(InboxItemAttachmentsStatus.COMPLETED);
      }
    },
    [inboxItemId, setInboxAttachmentsStatus]
  );

  useCompanyListener('inbox_item_attachments_completed', inboxOcrListener);

  return (
    <AutofillSuggestionsContext.Provider
      value={{
        inboxAttachmentsStatus,
        suggestionApplied,
        applySuggestion: resetFormValues,
      }}
    >
      {children}
    </AutofillSuggestionsContext.Provider>
  );
}

export function useAutofillSuggestionsContext() {
  const context = useContext(AutofillSuggestionsContext);

  if (!context) {
    throw new Error(
      'To use context, you must provide a AutofillSuggestionsProvider'
    );
  }

  return context;
}
