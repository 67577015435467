import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import throttle from 'lodash/throttle';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';

import type {
  BillPayableOptional,
  GetInboxItemMergeParams,
  GetInboxItemParams,
  GetParams,
  NewInboxItem,
  PostRejectParams,
  PostRestoreParams,
} from './types';
import { BillPayableOptionalDecoder, NewInboxItemDecoder } from './validations';

function invalidateInboxItemsQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries('inbox_items');
}

export const invalidateInboxItemsQueries = throttle(
  invalidateInboxItemsQueriesCall,
  FIVE_SECONDS_IN_MS
);

async function getInboxItems({
  organizationId,
  companyId,
  ...params
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/inbox_items`,
    { params }
  );

  return decodeResponse<NewInboxItem[]>(response, NewInboxItemDecoder.array());
}

async function getInboxItem({
  organizationId,
  companyId,
  inboxItemId,
}: GetInboxItemParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/inbox_items/${inboxItemId}`
  );

  return decodeResponse<NewInboxItem>(response, NewInboxItemDecoder);
}

async function postRejectInboxItems({
  organizationId,
  companyId,
  ...params
}: PostRejectParams) {
  await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/inbox_items/reject`,
    params
  );
}

async function postRestoreInboxItems({
  organizationId,
  companyId,
  ...params
}: PostRestoreParams) {
  await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/inbox_items/restore`,
    params
  );
}

export function useNewInboxItems(
  { companyId, organizationId, status }: GetParams,
  options?: UseQueryOptions<NewInboxItem[], ApiError>
) {
  const { data: inboxItems, ...rest } = useQuery<NewInboxItem[], ApiError>({
    enabled: Boolean(companyId) && Boolean(organizationId),
    queryKey: ['inbox_items', { companyId, organizationId, status }],
    queryFn: () => getInboxItems({ companyId, organizationId, status }),
    ...options,
  });

  return {
    inboxItems: withEmptyArrayDefault(inboxItems),
    ...rest,
  };
}

export function useInboxItem({
  companyId,
  organizationId,
  inboxItemId,
}: GetInboxItemParams) {
  const { data: inboxItem, ...rest } = useQuery<NewInboxItem, ApiError>({
    enabled:
      Boolean(companyId) && Boolean(organizationId) && Boolean(inboxItemId),
    queryKey: ['inbox_items', { companyId, organizationId, inboxItemId }],
    queryFn: () => getInboxItem({ companyId, organizationId, inboxItemId }),
  });

  return {
    inboxItem,
    ...rest,
  };
}

export function useRejectInboxItems() {
  const queryClient = useQueryClient();

  const { mutate: rejectInboxItems, ...rest } = useMutation<
    unknown,
    ApiError,
    PostRejectParams
  >({
    mutationFn: postRejectInboxItems,
    onSuccess: () => {
      invalidateInboxItemsQueries(queryClient);
    },
  });

  return {
    rejectInboxItems,
    ...rest,
  };
}

export function useRestoreInboxItems() {
  const queryClient = useQueryClient();

  const { mutate: restoreInboxItems, ...rest } = useMutation<
    unknown,
    ApiError,
    PostRestoreParams
  >({
    mutationFn: postRestoreInboxItems,
    onSuccess: () => {
      invalidateInboxItemsQueries(queryClient);
    },
  });

  return {
    restoreInboxItems,
    ...rest,
  };
}

export async function getInboxItemMerge({
  organizationId,
  companyId,
  inboxItemId,
  billId,
}: GetInboxItemMergeParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/inbox_items/${inboxItemId}/merge`,
    { params: { billId } }
  );

  return decodeResponse<BillPayableOptional>(
    response,
    BillPayableOptionalDecoder
  );
}

function setInboxItemMergeQueryKey({
  companyId,
  organizationId,
  inboxItemId,
  billId,
}: GetInboxItemMergeParams) {
  return [
    'inbox_items_merge',
    { companyId, organizationId, inboxItemId, billId },
  ];
}
export function useInboxItemMerge({
  companyId,
  organizationId,
  inboxItemId,
  billId,
}: GetInboxItemMergeParams) {
  const isEnabled =
    Boolean(companyId) && Boolean(organizationId) && Boolean(inboxItemId);

  const { data: bill, ...rest } = useQuery<BillPayableOptional, ApiError>({
    enabled: isEnabled,
    queryKey: setInboxItemMergeQueryKey({
      companyId,
      organizationId,
      inboxItemId,
      billId,
    }),
    queryFn: () =>
      getInboxItemMerge({ companyId, organizationId, inboxItemId, billId }),
  });

  return {
    bill,
    ...rest,
  };
}
