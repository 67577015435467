import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Card, CardContent } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from 'src/design-system/components';
import { MailIcon } from 'src/mui/_icons';
import { FormField, Input } from 'src/mui/_scss';

import { TextField, snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useCreateItauConsentments } from 'src/libs/finbits/Bank/ItauConsentments/ItauConsentments';
import { TradeName } from 'src/libs/finbits/Bank/types';
import { cnpjMask } from 'src/libs/finbits/Documents';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import AutoPaymentModal from '../AutoPaymentModal';
import AutoPaymentCard from '../AutoPaymentModal/AutoPaymentCard';

import styles from './ItauAutoPayment.module.scss';
import { ItauAutoPaymentResolver } from './validations';

type Props = PortalProps & {
  account: Account;
};

export default function ItauAutoPayment({
  onClose,
  onExit,
  open = false,
  account,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { connectItauConsentments, isLoading } = useCreateItauConsentments();
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);

  const form = useForm({
    resolver: zodResolver(ItauAutoPaymentResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      document: '',
      clientId: '',
      tempToken: '',
    },
  });

  async function handleRegister() {
    const isValid = await form.trigger();

    if (!isValid || !account.accountNumber || !account.branchNumber) return;

    setIsInvalidCredentials(false);

    const { document, clientId, tempToken } = form.getValues();

    connectItauConsentments(
      {
        organizationId,
        companyId,
        accountId: account.id,
        clientId,
        tempToken,
        document,
        accountNumber: account.accountNumber,
        branchNumber: account.branchNumber,
      },
      {
        onSuccess: () => {
          onClose();
          snackbar({
            variant: 'success',
            message: `Sua conta da instituição ${TradeName.ITAU} foi conectada para atualização automática das transações.`,
            title: 'Conta bancária conectada com sucesso',
          });
        },

        onError: (error) => {
          if (error.response?.status === 422) {
            setIsInvalidCredentials(true);
            return;
          }

          onClose();
          snackbar({
            variant: 'error',
            message: `Infelizmente, não foi possível conectar a conta da instituição ${TradeName.ITAU}. Por favor, tente novamente ou fale com nosso suporte.`,
            title: 'A conta bancária não foi conectada',
          });
        },
      }
    );
  }

  return (
    <AutoPaymentModal
      account={account}
      bankTradeName={TradeName.ITAU}
      disableConfirmButton={isLoading}
      errorTitle="A credencial informada está incorreta."
      errorDescription="Por favor, verifique o CNPJ da conta, o Client ID e o Token recebidos do Itaú e tente novamente."
      hasInvalidCredentials={isInvalidCredentials}
      onClose={onClose}
      onConfirmConnection={handleRegister}
      open={open}
      onExit={onExit}
    >
      <AutoPaymentCard stepIndex={1} heading="Fale com seu Gerente Itaú">
        <Card variant="outlined" className={styles.card}>
          <CardContent className={styles.cardContent}>
            <Typography color="grey.600" marginBottom={4}>
              Você deverá solicitar:
            </Typography>

            <Typography color="grey.600" marginBottom={4}>
              A <strong>Liberação da API de PIX pagamentos,</strong> para que
              possamos conectar à FinBits;
            </Typography>

            <Typography color="grey.600">
              As credenciais de conexão: <br />
              <strong>Client ID e Token.</strong>
            </Typography>
          </CardContent>
        </Card>

        <Box display="flex" flex-direction="column" gap={3} alignItems="center">
          <MailIcon className={styles.iconMail} />

          <Typography color="grey.600" variant="text-xs">
            Seu gerente do Itaú enviará as credenciais a você por e-mail.
          </Typography>
        </Box>

        <Typography color="grey.600" fontWeight="600" variant="text-xs">
          Obs.: Requer o Sispag Itaú contratado.
        </Typography>
      </AutoPaymentCard>

      <AutoPaymentCard
        stepIndex={2}
        heading="Verifique o e-mail recebido do Itaú"
      >
        <Typography color="grey.600">
          Você receberá um e-mail com as credenciais e informações do processo
          de conexão.
        </Typography>

        <Typography color="grey.600">
          Fique tranquilo, nós cuidaremos de tudo.
        </Typography>

        <Card variant="outlined" className={styles.card}>
          <CardContent className={styles.cardContent}>
            <Typography color="grey.600" marginBottom={4}>
              Basta que você copie e nos informe:
            </Typography>

            <Typography color="grey.600" marginBottom={4}>
              <strong>Client ID</strong> - consta no corpo do e-mail;
            </Typography>

            <Typography color="grey.600">
              <strong>Token</strong> - no arquivo anexo ao e-mail.
            </Typography>
          </CardContent>
        </Card>

        <Typography color="warning.600" fontWeight="600" variant="text-xs">
          Fique atento! O Token expira em 7 dias corridos.
        </Typography>
      </AutoPaymentCard>

      <AutoPaymentCard
        stepIndex={3}
        heading="Informe o CNPJ da conta e as credenciais recebidas por e-mail"
      >
        <Box
          component="form"
          gap={8}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <FormProvider {...form}>
            <FormField name="document">
              {(field) => (
                <TextField.Masked
                  {...field}
                  label="CNPJ"
                  placeholder="Informe o CNPJ da conta"
                  margin="normal"
                  mask={[cnpjMask]}
                  className={styles.cnpj}
                />
              )}
            </FormField>

            <FormField name="clientId" label="Client ID">
              {(field) => (
                <Input {...field} placeholder="Informe o Client ID recebido" />
              )}
            </FormField>

            <FormField name="tempToken" label="Token">
              {(field) => (
                <Input
                  {...field}
                  multiline
                  rows={4}
                  placeholder="Informe o Token recebido"
                />
              )}
            </FormField>
          </FormProvider>
        </Box>
      </AutoPaymentCard>
    </AutoPaymentModal>
  );
}
