import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { textColumn } from 'src/mui/_scss/Table';

import type { EntryUnfilledContextFields } from 'src/libs/finbits/Management/Entries/types';
import { unfilledContextField } from 'src/libs/finbits/Management/Entries/unfiledContextTranslations';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export function unfilledContextColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return textColumn({
    columnProps: {
      headerName: 'Informações pendentes',
      field: 'unfilledContextFields',
      minWidth: 120,
      flex: 1,
      aggregable: false,
      editable: false,
      valueGetter: (value: EntryUnfilledContextFields[] | null) =>
        value
          ?.map((value: EntryUnfilledContextFields) =>
            unfilledContextField(value)
          )
          .join(', ') ?? '',
      ...columnProps,
    },
  });
}
