import { EntryUnfilledContextFields } from './types';

const UNFILED_CONTEXT_DICTIONARY = {
  [EntryUnfilledContextFields.ASSIGNEES]: 'Aprovadores',
  [EntryUnfilledContextFields.ATTACHMENTS]: 'Anexos',
  [EntryUnfilledContextFields.CATEGORY_ID]: 'Categoria',
  [EntryUnfilledContextFields.CONTACT_ID]: 'Contato',
  [EntryUnfilledContextFields.RELEVANT_DATE]: 'Competência',
};

export function unfilledContextField(
  unfilledField?: EntryUnfilledContextFields | null
) {
  if (!unfilledField) return '';

  return UNFILED_CONTEXT_DICTIONARY[unfilledField];
}
