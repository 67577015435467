import type { ReactNode } from 'react';
import { useCallback } from 'react';

import compact from 'lodash/compact';
import { KBarProvider } from 'kbar';
import {
  BuildingIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  CodeIcon,
  CurrencyDollarCircleIcon,
  DashboardIcon,
  DataFlowIcon,
  GridIcon,
  HomeLineIcon,
  InboxIcon,
  LayoutIcon,
  LogoutIcon,
  MessageBirdIcon,
  ServerIcon,
  SwitchHorizontalIcon,
  TagIcon,
  UserIcon,
  UsersIcon,
  UsersPlusIcon,
} from 'src/mui/_icons';
import { useNavigate, useParams } from 'react-router';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { PaymentIcon, useOpenPortal } from 'src/ui';

import { useUserCompanies } from 'src/libs/finbits/Organization/Users';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { toggleChat } from 'src/libs/messageBird';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import CreateCategoryModal from 'src/features/categories/CreateCategoryModal';
import CreateContactDrawer from 'src/features/contacts/CreateContactDrawer';
import CreateFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/CreateFinancialEntryDrawer';
import CreateScheduledDrawer from 'src/features/scheduled/CreateScheduledDrawer';
import LabelModal from 'src/features/labels/LabelModal';
import CreatePublicApiTokenModal from 'src/features/companies/PublicApiTokens/CreatePublicApiTokenModal';

import useBarAction from './useBarAction';
import CommandBarConsumer from './CommandBarConsumer';

type Props = {
  children: ReactNode;
};

export default function CommandBarProvider({ children }: Props) {
  const { isEnabled: isOverviewEnabled } = useExternalFeatureFlag(
    Feature.COMPANY_OVERVIEW
  );

  const navigate = useNavigate();

  const openPortal = useOpenPortal();

  const { companies } = useUserCompanies();
  const { companyId, organizationId } = useParams();

  const hasCompany = Boolean(companies?.length);

  const onNavigate = useCallback(
    (routeKey: RouteKey, search?: string) => {
      navigate({
        pathname: generateCompanyPath(routeKey, {
          companyId,
          organizationId,
        }),
        search: search,
      });
    },
    [companyId, organizationId, navigate]
  );

  const actions = compact([
    useBarAction({
      action: {
        icon: <CalendarIcon />,
        id: 'add-bill-receivable',
        keywords: 'criar lançamento programado',
        name: 'Lançamento programado',
        perform: () => openPortal(CreateScheduledDrawer),
        section: 'Criar',
        subtitle: 'Abre formulário para criar um lançamento programado',
      },
      permission: [
        {
          action: 'create',
          resource: 'receivables',
          scope: 'company',
        },
        {
          action: 'create',
          resource: 'bills',
          scope: 'company',
        },
      ],
    }),
    useBarAction({
      action: {
        icon: <CalendarIcon />,
        id: 'add-bill',
        keywords: 'criar lançamento programado pagamento',
        name: 'Pagamento programado',
        parent: 'add-bill-receivable',
        perform: () =>
          openPortal(CreateScheduledDrawer, {
            initialValues: { type: BalanceType.DEBIT },
          }),
        section: 'Criar',
        subtitle: 'Abre formulário para criar um pagamento programado',
      },
      permission: {
        action: 'create',
        resource: 'bills',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <CalendarIcon />,
        id: 'add-receivable',
        keywords: 'criar lançamento programado recebimento',
        name: 'Recebimento programado',
        parent: 'add-bill-receivable',
        perform: () =>
          openPortal(CreateScheduledDrawer, {
            initialValues: { type: BalanceType.CREDIT },
          }),
        section: 'Criar',
        subtitle: 'Abre formulário para criar um recebimento programado',
      },
      permission: {
        action: 'create',
        resource: 'receivables',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <CheckIcon />,
        id: 'add-financial-entry',
        keywords: 'criar lançamento realizado',
        name: 'Lançamento realizado',
        perform: () => openPortal(CreateFinancialEntryDrawer),
        section: 'Criar',
        subtitle: 'Abre formulário para criar um lançamento realizado',
      },
      permission: {
        action: 'create',
        resource: 'financialEntries',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <UsersPlusIcon />,
        id: 'add-contact',
        keywords: 'criar contato',
        name: 'Contato',
        perform: () => openPortal(CreateContactDrawer),
        section: 'Criar',
        subtitle: 'Abre formulário para criar contato',
      },
      permission: { action: 'create', resource: 'contacts', scope: 'company' },
    }),
    useBarAction({
      action: {
        icon: <CodeIcon />,
        id: 'add-public-api-token',
        keywords: 'criar token da api publica',
        name: 'Token da API Pública',
        perform: () => openPortal(CreatePublicApiTokenModal),
        section: 'Criar',
        subtitle: 'Cria um novo token da API Pública para esta empresa',
      },
      permission: {
        action: 'create',
        resource: 'publicApiTokens',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <ServerIcon />,
        id: 'add-category',
        keywords: 'criar categoria',
        name: 'Categoria',
        perform: () => openPortal(CreateCategoryModal),
        section: 'Criar',
        subtitle: 'Abre formulário para criar categoria',
      },
      permission: {
        action: 'create',
        resource: 'categories',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <TagIcon />,
        id: 'add-label',
        keywords: 'criar etiqueta',
        name: 'Etiqueta',
        perform: () => openPortal(LabelModal),
        section: 'Criar',
        subtitle: 'Abre formulário para criar etiqueta',
      },
      permission: {
        action: 'create',
        resource: 'labels',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <LayoutIcon />,
        id: 'navigate',
        keywords: 'navegar ir para',
        name: 'Navegar para...',
        section: 'Navegar',
        subtitle: 'Navega para página escolhida',
      },
    }),
    useBarAction({
      action: {
        icon: <InboxIcon />,
        id: 'navigate-inbox',
        keywords: 'navegar ir caixa entrada inbox',
        name: 'Caixa de entrada',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.INBOX_ITEMS),
        section: 'Navegar',
        subtitle: 'Navega até página da Caixa de entrada',
      },
      permission: {
        action: 'index',
        resource: 'inboxItems',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <CurrencyDollarCircleIcon />,
        id: 'navigate-bills',
        keywords: 'navegar ir contas pagar',
        name: 'Contas a pagar',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.BILLS_TO_PAY),
        section: 'Navegar',
        subtitle: 'Navega até página de Contas a pagar',
      },
      permission: { action: 'index', resource: 'bills', scope: 'company' },
    }),
    useBarAction({
      action: {
        icon: <CheckCircleIcon />,
        id: 'navigate-approval',
        keywords: 'navegar ir aprovações',
        name: 'Aprovações',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.APPROVAL),
        section: 'Navegar',
        subtitle: 'Navega até página de Aprovações',
      },
      permission: {
        action: 'index_pending_approval',
        resource: 'bills',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <SwitchHorizontalIcon />,
        id: 'navigate-entries',
        keywords: 'navegar ir lançamentos',
        name: 'Lançamentos',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.ENTRIES),
        section: 'Navegar',
        subtitle: 'Navega até página de Lançamentos',
      },
      permission: {
        action: 'index',
        resource: 'financialStatements',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <DashboardIcon />,
        id: 'navigate-dashboard',
        keywords: 'navegar ir resumo empresa atual',
        name: 'Resumo - Empresa Atual',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.DASHBOARD),
        section: 'Navegar',
        subtitle: 'Navega até página do Resumo da Empresa atual',
      },
      permission: {
        action: 'show_dashboard',
        resource: 'webappPages',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <DashboardIcon />,
        id: 'navigate-overview',
        keywords: 'navegar ir visão visao geral empresa resumo',
        name: 'Visão Geral',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.OVERVIEW),
        section: 'Navegar',
        subtitle: 'Navega até página do Visão geral da empresa atual',
      },
      enabled: isOverviewEnabled,
      permission: {
        action: 'show_overview',
        resource: 'webappPages',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <PaymentIcon />,
        id: 'navigate-nota-fiscal',
        keywords: 'navegar ir nota notas fiscal fiscais',
        name: 'Notas fiscais',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.NOTA_FISCAL),
        section: 'Navegar',
        subtitle: 'Navega até página de Notas fiscais',
      },
      permission: {
        action: 'index',
        resource: 'notasFiscais',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <HomeLineIcon />,
        id: 'navigate-company',
        keywords: 'navegar ir empresa',
        name: 'Empresa',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.COMPANY),
        section: 'Navegar',
        subtitle: 'Navega até página de Empresa',
      },
      permission: {
        action: 'show',
        resource: 'companies',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <UsersIcon />,
        id: 'navigate-contacts',
        keywords: 'navegar ir contatos',
        name: 'Contatos',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.CONTACTS),
        section: 'Navegar',
        subtitle: 'Navega até página de Cadastro de Contatos',
      },
      permission: {
        action: 'index',
        resource: 'contacts',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <BuildingIcon />,
        id: 'navigate-accounts',
        keywords: 'navegar ir contas',
        name: 'Contas',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.ACCOUNTS),
        section: 'Navegar',
        subtitle: 'Navega até página de Cadastro de Contas',
      },
      permission: {
        action: 'index',
        resource: 'accounts',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <GridIcon />,
        id: 'navigate-categories',
        keywords: 'navegar ir categorias',
        name: 'Categorias',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.CATEGORIES),
        section: 'Navegar',
        subtitle: 'Navega até página de Cadastro de Categorias',
      },
      permission: {
        action: 'index',
        resource: 'categories',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <TagIcon />,
        id: 'navigate-labels',
        keywords: 'navegar ir etiquetas classificação',
        name: 'Etiquetas',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.LABELS),
        section: 'Navegar',
        subtitle: 'Navega até página de Cadastro de Etiquetas',
      },
      permission: {
        action: 'index',
        resource: 'classifications',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <BuildingIcon />,
        id: 'navigate-companies',
        keywords: 'navegar ir resumo todas empresas',
        name: 'Resumo - Todas as empresas',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.BALANCES),
        section: 'Navegar',
        subtitle: 'Navega até página do Resumo de Todas as empresas',
      },
      permission: {
        action: 'index',
        resource: 'companiesBalances',
        scope: 'organization',
      },
      enabled: hasCompany,
    }),
    useBarAction({
      action: {
        icon: <UsersIcon />,
        id: 'navigate-users',
        keywords: 'navegar ir usuários',
        name: 'Usuários',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.USERS),
        section: 'Navegar',
        subtitle: 'Navega até página de Usuários',
      },
      permission: { action: 'index', resource: 'users', scope: 'organization' },
      enabled: hasCompany,
    }),
    useBarAction({
      action: {
        icon: <UserIcon />,
        id: 'navigate-profile',
        keywords: 'navegar ir meu perfil',
        name: 'Meu perfil',
        parent: 'navigate',
        perform: () => onNavigate(RouteKey.PROFILE),
        section: 'Navegar',
        subtitle: 'Navega até página de Meu perfil',
      },
    }),
    useBarAction({
      action: {
        icon: <LogoutIcon />,
        id: 'navigate-logout',
        keywords: 'navegar sair logout',
        name: 'Sair',
        parent: 'navigate',
        perform: () => navigate('/logout'),
        section: 'Navegar',
        subtitle: 'Sair da plataforma',
      },
    }),
    useBarAction({
      action: {
        icon: <DataFlowIcon />,
        id: 'approval-rules-config',
        keywords: 'Configurar fluxo de aprovação aprovadores',
        name: 'Configurar fluxo de aprovação',
        section: 'Fluxo de aprovação',
        perform: () => onNavigate(RouteKey.CONFIG_APPROVAL_FLOW),
        subtitle:
          'Ative/desative o fluxo de aprovação e defina o modo de atribuição de aprovadores',
      },
      permission: {
        action: 'update_bills_approvals',
        resource: 'companiesOptions',
        scope: 'company',
      },
    }),
    useBarAction({
      action: {
        icon: <MessageBirdIcon />,
        id: 'message-bird-chat',
        keywords: 'chat ajuda suporte analista socorro',
        name: 'Ajuda',
        section: 'Suporte',
        perform: toggleChat,
        subtitle: 'Abrir chat com suporte',
      },
    }),
  ]);

  return (
    <KBarProvider
      actions={actions}
      options={{ disableDocumentLock: true }}
      key={companyId}
    >
      <CommandBarConsumer />
      {children}
    </KBarProvider>
  );
}
