import { generatePath } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import InboxItems from 'src/pages/Organizations/Companies/InboxItems';
import BillsToPay from 'src/pages/Organizations/Companies/BillsToPay';
import Approval from 'src/pages/Organizations/Companies/Approval';
import ApprovalRules from 'src/pages/Organizations/Companies/ApprovalRules';
import ConfigApprovalFlow from 'src/pages/Organizations/Companies/ConfigApprovalFlow';
import Entries from 'src/pages/Organizations/Companies/Entries';
import Company from 'src/pages/Organizations/Companies/Company';
import NotaFiscal from 'src/pages/Organizations/Companies/NotaFiscal';
import Dashboard from 'src/pages/Organizations/Companies/Dashboard';
import Balances from 'src/pages/Organizations/Companies/Balances';
import Users from 'src/pages/Organizations/Companies/Users';
import Accounts from 'src/pages/Organizations/Companies/Accounts';
import Contacts from 'src/pages/Organizations/Companies/Contacts';
import Classifications from 'src/pages/Organizations/Companies/Classifications';
import Categories from 'src/pages/Organizations/Companies/Categories';
import FinBot from 'src/pages/Organizations/Companies/FinBot';
import UserProfile from 'src/pages/UserProfile';
import SignIn from 'src/pages/SignIn';
import UserSignUp from 'src/pages/SignUp/User';
import OrganizationSignUp from 'src/pages/SignUp/Organization';
import Overview from 'src/pages/Organizations/Companies/Overview';
import RecoverPassword from 'src/pages/RecoverPassword';
import RedefinePassword from 'src/pages/Authentications/RedefinePassword';
import ConsentmentRedirect from 'src/pages/OpenBanking/ConsentmentRedirect';
import PurchaseOrder from 'src/pages/PurchaseOrder';
import Logout from 'src/pages/Logout';

import InboxItemsProvider from 'src/features/inbox-items/InboxItemsProvider';

import type { OrganizationRoute, PublicRoute } from './types';
import { RouteKey } from './types';

export const ORGANIZATION_ROUTE_PATH =
  '/organizations/:organizationId/companies/:companyId';

export const publicRoutes: PublicRoute[] = [
  {
    id: 'purchase_order',
    path: '/p/:publicId',
    title: 'Solicitação de pagamento ou reembolso',
    element: <PurchaseOrder />,
  },
  {
    id: 'open_banking_authentication',
    path: '/open_banking/authentication/:provider',
    title: 'Autenticação Open Banking',
    element: <ConsentmentRedirect />,
  },
  {
    id: 'logout',
    path: '/logout',
    title: 'Logout',
    element: <Logout />,
  },
];

export const publicAuthenticationRoutes: PublicRoute[] = [
  {
    id: 'login',
    path: '/login',
    title: 'Login',
    element: <SignIn />,
  },
  {
    id: 'user_signup',
    path: '/signup/:organizationId',
    title: 'Cadastro de usuário',
    element: <UserSignUp />,
  },
  {
    id: 'organization_signup',
    path: '/organization-signup',
    title: 'Cadastro de empresa',
    element: <OrganizationSignUp />,
  },
  {
    id: 'recover_password',
    path: '/recover_password',
    title: 'Recuperar senha',
    element: <RecoverPassword />,
  },
  {
    id: 'redefine_password',
    path: '/authentications/reset',
    title: 'Redefinir senha',
    element: <RedefinePassword />,
  },
  {
    id: 'trial_signup',
    path: '/signup-trial',
    title: 'Trial',
    element: <OrganizationSignUp />,
  },
];

export const organizationRoutes: OrganizationRoute[] = [
  {
    id: RouteKey.INBOX_ITEMS,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.INBOX_ITEMS}`,
    title: 'Caixa de entrada',
    element: (
      <InboxItemsProvider>
        <InboxItems />
      </InboxItemsProvider>
    ),
    permission: {
      action: 'index',
      resource: 'inboxItems',
      scope: 'company',
    },
  },
  {
    id: RouteKey.BILLS_TO_PAY,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.BILLS_TO_PAY}`,
    title: 'Contas a pagar',
    element: <BillsToPay />,
    permission: {
      action: 'index',
      resource: 'bills',
      scope: 'company',
    },
  },
  {
    id: RouteKey.APPROVAL,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.APPROVAL}`,
    title: 'Aprovações',
    element: <Approval />,
    permission: {
      action: 'index_pending_approval',
      resource: 'bills',
      scope: 'company',
    },
  },
  {
    id: RouteKey.CONFIG_APPROVAL_FLOW,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.CONFIG_APPROVAL_FLOW}`,
    title: 'Fluxo de aprovação',
    element: <ConfigApprovalFlow />,
    permission: {
      action: 'show',
      resource: 'companies',
      scope: 'company',
    },
  },
  {
    id: RouteKey.APPROVAL_RULES,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.APPROVAL_RULES}`,
    title: 'Regras de aprovação',
    element: <ApprovalRules />,
    permission: {
      action: 'index',
      resource: 'approvalsRules',
      scope: 'company',
    },
  },
  {
    id: RouteKey.ENTRIES,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.ENTRIES}`,
    title: 'Lançamentos',
    element: <Entries />,
    permission: {
      action: 'index',
      resource: 'financialStatements',
      scope: 'company',
    },
  },
  {
    id: RouteKey.DASHBOARD,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.DASHBOARD}`,
    title: 'Resumo - Empresa atual',
    element: <Dashboard />,
    permission: {
      action: 'show_dashboard',
      resource: 'webappPages',
      scope: 'company',
    },
  },
  {
    id: RouteKey.NOTA_FISCAL,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.NOTA_FISCAL}`,
    title: 'Notas fiscais',
    element: <NotaFiscal />,
    permission: {
      action: 'index',
      resource: 'notasFiscais',
      scope: 'company',
    },
  },
  {
    id: RouteKey.COMPANY,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.COMPANY}`,
    title: 'Empresa',
    element: <Company />,
    permission: {
      action: 'show',
      resource: 'companies',
      scope: 'company',
    },
  },
  {
    id: RouteKey.BALANCES,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.BALANCES}`,
    title: 'Resumo - Todas as empresas',
    element: <Balances />,
    permission: {
      action: 'index',
      resource: 'companiesBalances',
      scope: 'organization',
    },
  },
  {
    id: RouteKey.USERS,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.USERS}`,
    title: 'Usuários',
    element: <Users />,
    permission: {
      action: 'index',
      resource: 'users',
      scope: 'organization',
    },
  },
  {
    id: RouteKey.PROFILE,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.PROFILE}`,
    title: 'Meu perfil',
    element: <UserProfile />,
  },
  {
    id: RouteKey.ACCOUNTS,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.ACCOUNTS}`,
    title: 'Contas',
    element: <Accounts />,
  },
  {
    id: RouteKey.CONTACTS,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.CONTACTS}`,
    title: 'Contatos',
    element: <Contacts />,
  },
  {
    id: RouteKey.LABELS,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.LABELS}`,
    title: 'Etiquetas',
    element: <Classifications />,
  },
  {
    id: RouteKey.CATEGORIES,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.CATEGORIES}`,
    title: 'Categorias',
    element: <Categories />,
  },
  {
    id: RouteKey.FinBot,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.FinBot}`,
    title: 'FinBot',
    element: <FinBot />,
  },
  {
    id: RouteKey.OVERVIEW,
    path: `${ORGANIZATION_ROUTE_PATH}/${RouteKey.OVERVIEW}`,
    title: 'Visão geral',
    element: <Overview />,
  },
];

export const routes = [
  ...publicAuthenticationRoutes,
  ...organizationRoutes,
  ...publicRoutes,
];

type Params =
  | {
      companyId?: string;
      organizationId?: string;
    }
  | Record<string, string>;

export function generateCompanyPath(
  key: RouteKey,
  { companyId, organizationId = '', ...rest }: Params
) {
  const searchParams = rest ? `?${createSearchParams(rest).toString()}` : '';

  if (!companyId) {
    return generatePath(
      `/organizations/:organizationId/${key}${searchParams}`,
      {
        organizationId,
      }
    );
  }

  return generatePath(`${ORGANIZATION_ROUTE_PATH}/${key}${searchParams}`, {
    companyId,
    organizationId,
  });
}
