import { Box } from '@mui/material';

import { BillStatus } from 'src/libs/finbits/Bills/types';
import { EntrySituation } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import UnfilledContextAlert from 'src/features/entries/UnfilledContextAlert';
import ReprovedReasonAlert from 'src/features/bills-to-pay/EditBillDrawer/ReprovedReasonAlert';
import PaymentFailedAlert from 'src/features/bills-to-pay/EditBillDrawer/PaymentFailedAlert';
import SuggestionsAppliedAlert from 'src/features/bills-to-pay/BillContent/autofill-suggestions/SuggestionsAppliedAlert';

import styles from './BillAlerts.module.scss';

type Props = {
  bill?: BillPayable | BillPayableOptional;
};

export default function BillAlerts({ bill }: Props) {
  const isReproved = bill?.status === BillStatus.REPROVED;
  const isFailed = bill?.currentSituation === EntrySituation.FAILED;

  return (
    <Box className={styles.wrapper}>
      <SuggestionsAppliedAlert />

      {isReproved && <ReprovedReasonAlert bill={bill as BillPayable} />}
      {isFailed && <PaymentFailedAlert bill={bill as BillPayable} />}

      <UnfilledContextAlert entry={bill} entryType={EntryType.BILL} />
    </Box>
  );
}
