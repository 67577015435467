import type { ReactNode } from 'react';

import type { ControllerProps, ControllerRenderProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { InputUnstyledProps } from '@mui/base';
import { InfoCircleIcon } from 'src/mui/_icons/InfoCircleIcon';
import { Typography } from 'src/design-system/components';

import { Tooltip } from 'src/mui';

import HelperText from './HelperText';
import styles from './FormField.module.scss';

export type FormFieldProps = {
  label?: ReactNode | ((value: string) => ReactNode);
  placeholder?: string;
  required?: boolean;
  tooltip?: string;
  helperText?: string;
  endAdornment?: ReactNode;
  children?: (
    props: Pick<InputUnstyledProps, 'error' | 'aria-label' | 'id'> &
      ControllerRenderProps
  ) => ReactNode;
} & Omit<ControllerProps, 'render'>;

export default function FormField({
  name,
  label,
  required = false,
  tooltip,
  helperText,
  children,
  disabled,
  ...rest
}: FormFieldProps) {
  const ariaLabel = typeof label === 'string' ? label : undefined;

  return (
    <Controller
      {...rest}
      name={name}
      render={({ field, fieldState }) => {
        const labelForId = `form-field-${field.name}`;

        return (
          <fieldset className={styles.root}>
            {label && (
              <label
                aria-required={required}
                className={styles.label}
                htmlFor={labelForId}
              >
                {required && <span className={styles.required}>*</span>}
                {typeof label === 'function' ? label(field.value) : label}

                {tooltip && (
                  <Tooltip title={tooltip}>
                    <InfoCircleIcon />
                  </Tooltip>
                )}
              </label>
            )}
            {children?.({
              ...field,
              disabled: disabled,
              id: labelForId,
              error: !!fieldState.error,
              'aria-label': ariaLabel,
            })}
            {!!fieldState.error && (
              <HelperText error={!!fieldState.error}>
                {fieldState.error?.message || fieldState.error?.root?.message}
              </HelperText>
            )}
            {helperText && !fieldState.error && (
              <Typography color="text.secondary">{helperText}</Typography>
            )}
          </fieldset>
        );
      }}
    />
  );
}
