import type { MouseEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ListItemText, Menu, Stack } from '@mui/material';
import { BankIcon, CheckIcon, SearchIcon } from 'src/mui/_icons';

import { Autocomplete, Badge } from 'src/mui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { accountFilterOptions } from 'src/features/entries/Fields/AccountsField';

import styles from './AccountsAutocomplete.module.scss';

type Props = {
  selectedAccountIds: string[];
  setSelectedAccountIds: (ids: string[]) => void;
};

export function AccountsAutocomplete({
  selectedAccountIds,
  setSelectedAccountIds,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { companyId, organizationId } = useCompanyParams();

  const { accounts, isLoading: isLoadingAccounts } = useAccounts({
    companyId,
    organizationId,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState(
    accounts.filter((a) => selectedAccountIds.includes(a.id))
  );

  useEffect(() => {
    if (selectedAccountIds.length) {
      const selectedAccounts = accounts.filter((a) =>
        selectedAccountIds.includes(a.id)
      );

      setValue(selectedAccounts);
    }
  }, [accounts, selectedAccountIds]);

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handleAccountsChange(accounts: Account[]) {
    if (!!accounts && accounts.length > 0) {
      setValue(accounts);
    }
  }

  function handleClose() {
    setAnchorEl(null);
    setSelectedAccountIds(value.map(({ id }) => id));
  }

  function selectAll() {
    setValue(accounts);
  }

  function unselectAll() {
    setValue([]);
  }

  return (
    <>
      <LoadingButton
        loading={isLoadingAccounts}
        variant="text"
        onClick={handleClick}
        startIcon={<BankIcon />}
        endIcon={
          <Badge color="secondary" badgeContent={value.length} showZero />
        }
      >
        Contas
      </LoadingButton>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        disablePortal
        classes={{ paper: styles.paper }}
        onClose={handleClose}
        onAnimationEnd={() => inputRef.current?.focus()}
      >
        <Autocomplete.Search
          classes={{ popper: styles.popper }}
          open
          openOnFocus
          disableClearable
          disablePortal
          disableCloseOnSelect
          multiple
          limitTags={5}
          value={value}
          options={accounts}
          forcePopupIcon={false}
          loading={isLoadingAccounts}
          filterOptions={accountFilterOptions}
          getOptionLabel={(account) => account.name}
          onChange={(_, value) => handleAccountsChange(value)}
          TextFieldProps={{
            inputRef,
            placeholder: 'Buscar',
            InputProps: {
              startAdornment: <SearchIcon />,
            },
          }}
          renderTags={() => null}
          renderOption={(_, account, state) => (
            <>
              <ListItemText
                primary={account.name}
                secondary={account.accountNumber}
              />

              {state.selected && <CheckIcon />}
            </>
          )}
        />

        <Stack className={styles.footer}>
          <Button onClick={unselectAll}>Desmarcar todas</Button>
          <Button onClick={selectAll}>Marcar todas</Button>
        </Stack>
      </Menu>
    </>
  );
}
