import { useOpenPortal } from 'src/ui/PortalProvider';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { ScheduledStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useRestoreInboxItems } from 'src/libs/finbits/Management/NewInboxItems';

import useInboxItemsActions from 'src/features/inbox-items/useInboxItemsActions';
import CreateBillModal from 'src/features/bills-to-pay/CreateBillModal';
import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';
import MergeBillModal from 'src/features/bills-to-pay/MergeBillModal';
import ConciliationModal from 'src/features/conciliations/ConciliationModal';

type Props = {
  inboxItem?: NewInboxItem;
};

export default function useInboxItemActions({ inboxItem }: Props) {
  const inboxItemId = inboxItem?.id;
  const { organizationId, companyId } = useCompanyParams();
  const openPortal = useOpenPortal();

  const { restoreInboxItems } = useRestoreInboxItems();

  const inboxItemsIds = inboxItemId ? [inboxItemId] : [];
  const { onRejectionMany } = useInboxItemsActions({
    inboxItemsIds,
    onSuccess: onSuccessRejection,
  });

  const openConfirmDialog = useOpenConfirmDialog();

  const { navigateToNext } = useInboxItemsParams();

  const isAllowedCreateBill = useAllowedPermission({
    action: 'create',
    resource: 'bills',
  });

  const isAllowedUpdateBill = useAllowedPermission({
    action: 'update',
    resource: 'bills',
  });

  const isAllowedRejectManyInboxItems = useAllowedPermission({
    action: 'reject_many',
    resource: 'inboxItems',
  });

  const isAllowedRestoreManyInboxItems = useAllowedPermission({
    action: 'restore_many',
    resource: 'inboxItems',
  });

  const isAllowedActions =
    isAllowedCreateBill ||
    isAllowedUpdateBill ||
    isAllowedRejectManyInboxItems ||
    isAllowedRestoreManyInboxItems;

  function handleRestore() {
    if (!inboxItemId) return;

    restoreInboxItems(
      {
        companyId,
        organizationId,
        inboxItemsIds: [inboxItemId],
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Item restaurado',
          });

          navigateToNext(inboxItemId);
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao restaurar item',
          });
        },
      }
    );
  }

  function onSuccessRejection() {
    if (!inboxItemId) return;

    navigateToNext(inboxItemId);
  }

  function openRestoreConfirmationDialog() {
    openConfirmDialog({
      variant: 'primary',
      title: 'Restaurar entrada',
      content:
        'Ao confirmar, o item será movido de volta para a caixa de entrada.',
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
      onConfirm: handleRestore,
    });
  }

  function openCreateScheduledDrawer() {
    if (!inboxItemId) return;

    return openPortal(CreateBillModal, {
      inboxItemId,
      inboxAttachmentsStatus: inboxItem.attachmentsStatus,
      onSuccess: () => navigateToNext(inboxItemId),
    });
  }

  function handleConciliate(scheduledEntry: ScheduledStatementEntry) {
    if (!inboxItemId) return;

    openPortal(MergeBillModal, {
      inboxItemId,
      inboxAttachmentsStatus: inboxItem.attachmentsStatus,
      billId: scheduledEntry.id,
      onSuccess: () => navigateToNext(inboxItemId),
    });
  }

  function openConciliationDrawer() {
    openPortal(ConciliationModal, {
      onConciliate: handleConciliate,
    });
  }

  return {
    onCreateEntry: openCreateScheduledDrawer,
    onConciliation: openConciliationDrawer,
    onRestore: openRestoreConfirmationDialog,
    onRejection: onRejectionMany,
    isAllowedActions,
    isAllowedCreateBill,
    isAllowedUpdateBill,
    isAllowedRejectManyInboxItems,
    isAllowedRestoreManyInboxItems,
  };
}
