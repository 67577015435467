import { endOfYesterday } from 'date-fns';
import type { QuickFilter } from 'src/mui/_scss/Table/FilterPanel/QuickFilters/types';

import { format } from 'src/libs/finbits/Date';
export const panelQuickFilters: QuickFilter[] = [
  {
    label: 'Há Informações pendentes',
    filterItems: [
      {
        field: 'unfilledContextFields',
        operator: 'isNotEmpty',
        id: 'unfilled-context-fields-1',
      },
    ],
  },
  {
    label: 'Atrasados',
    filterItems: [
      {
        field: 'date',
        operator: 'before',
        value: format(endOfYesterday(), 'yyyy-MM-dd'),
        id: 'overdue-1',
      },
      {
        field: 'currentSituation',
        operator: 'not',
        value: 'paid',
        id: 'overdue-2',
      },
      {
        field: 'currentSituation',
        operator: 'not',
        value: 'settled',
        id: 'overdue-3',
      },
    ],
  },
];
