import { Box, Card, CardContent } from '@mui/material';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';

import {
  format,
  isFuture,
  isToday,
  startOfYesterday,
} from 'src/libs/finbits/Date';
import type { FinancialStatementEntriesSummary } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import BalanceAmount from './BalanceAmount';
import styles from './EntriesSummary.module.scss';
import Header from './Header';
import MoneyBox from './MoneyBox';

export type Props = {
  data: FinancialStatementEntriesSummary;
  selectedStartDate: Date;
};

function getCurrentBalanceDate(endDate: Date) {
  if (isToday(endDate) || isFuture(endDate)) {
    return startOfYesterday();
  }
  return endDate;
}

export default function EntriesSummary({ data, selectedStartDate }: Props) {
  const {
    accounts,
    summary: {
      creditAmount,
      debitAmount,
      finalBalance,
      initialBalance,
      projectedBalance,
      totalCredit,
      totalDebit,
    },
  } = data;
  const hasInconsistency = accounts.some(
    ({ inconsistency }) => inconsistency.amount > 0
  );

  const someBalancePending = accounts.some(
    ({ balancePending }) => balancePending
  );

  const currentBalanceDate = getCurrentBalanceDate(selectedStartDate);

  return (
    <Box
      display="grid"
      gridTemplateRows="repeat(4, 1fr)"
      gap={{ xs: 2, xl: 6 }}
      paddingLeft={6}
      height="100%"
      data-testid="entries-summary"
    >
      <Card className={styles.card} variant="outlined">
        <CardContent>
          <Header title="Saldo inicial no período" />

          <MoneyBox text={`Em ${format(currentBalanceDate, 'dd/MM/yyyy')}`}>
            <BalanceAmount
              amount={initialBalance.amount}
              amountType={initialBalance.type}
              hasInconsistency={hasInconsistency}
              someBalancePending={someBalancePending}
              accounts={accounts}
              showIconInfo={false}
            />
          </MoneyBox>
        </CardContent>
      </Card>

      <Card className={styles.card} variant="outlined">
        <CardContent>
          <Header
            title="Entradas"
            Icon={<ArrowCircleUpIcon color="success" />}
          />

          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <MoneyBox
              text="Previstas"
              amount={totalCredit}
              textColor="grey.500"
            />
            <MoneyBox text="Realizadas" amount={creditAmount} />
          </Box>
        </CardContent>
      </Card>

      <Card className={styles.card} variant="outlined">
        <CardContent>
          <Header title="Saídas" Icon={<ArrowCircleDownIcon color="error" />} />

          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <MoneyBox
              text="Previstas"
              amount={totalDebit}
              textColor="grey.500"
            />
            <MoneyBox text="Realizadas" amount={debitAmount} />
          </Box>
        </CardContent>
      </Card>

      <Card className={styles.card} variant="outlined">
        <CardContent>
          <Header title="Saldo ao final do período" />

          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <MoneyBox text="Previsto">
              <BalanceAmount
                amount={projectedBalance.amount}
                amountType={projectedBalance.type}
                hasInconsistency={hasInconsistency}
                someBalancePending={someBalancePending}
                accounts={accounts}
                textColor="grey.500"
                showIconInfo={false}
              />
            </MoneyBox>

            <MoneyBox text="Realizado">
              <BalanceAmount
                amount={finalBalance.amount}
                amountType={finalBalance.type}
                hasInconsistency={hasInconsistency}
                someBalancePending={someBalancePending}
                accounts={accounts}
                showIconInfo={false}
              />
            </MoneyBox>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
