import { useCallback, useLayoutEffect, useMemo } from 'react';

import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useBeforeUnload } from 'react-router-dom';
import omit from 'lodash/omit';

import * as Storage from 'src/libs/finbits/Storage';

type Props = {
  gridApi: GridApiPremium;
  storageKey?: string;
  pageSize?: number;
};

function buildInitialState(pageSize?: number) {
  if (pageSize)
    return {
      pagination: {
        paginationModel: { page: 0, pageSize },
      },
    };

  return {};
}

export function useStoredTableStatus({ gridApi, storageKey, pageSize }: Props) {
  const storeGridState = useCallback(() => {
    if (gridApi.exportState && storageKey) {
      const updatedFilter = omit(gridApi.exportState(), [
        'filter.filterModel.quickFilterValues',
      ]);

      Storage.setItem(storageKey, updatedFilter);
    }
  }, [gridApi, storageKey]);

  useBeforeUnload(storeGridState);

  useLayoutEffect(() => {
    return storeGridState;
  }, [storeGridState]);

  const initialState = useMemo(() => {
    if (!storageKey) return buildInitialState(pageSize);

    return Storage.getItem(storageKey, buildInitialState(pageSize));
  }, [storageKey, pageSize]);

  return { initialState };
}
