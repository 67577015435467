import type t from 'zod';

import type {
  CategorySuggestionDecoder,
  ContactSuggestionDecoder,
  EntrySuggestionsDecoder,
  NewEntrySuggestionDecoder,
  PaymentDetailsSuggestionDecoder,
  SuggestionFieldsDecoder,
} from './validations';

export type EntrySuggestionsPostParams = {
  organizationId?: string;
  companyId?: string;
  contactId: string;
  type: string;
};

export type NewEntrySuggestionsPostParams = {
  organizationId: string;
  companyId: string;
  params: {
    contactId?: string | null;
    type?: string;
    inboxItemsIds?: string[];
    attachmentsIds?: string[];
  };
};

export enum SuggestionSource {
  INBOX_ITEM = 'inbox_item',
  CONTACT = 'contact',
  DDA = 'dda',
  EMAIL = 'email',
  NOTA_FISCAL = 'nota_fiscal',
  PRODUCT_NOTA_FISCAL = 'product_nota_fiscal',
  PURCHASE_ORDER = 'purchase_order',
  UPLOAD = 'upload',
  WHATSAPP = 'whatsapp',
  ATTACHMENT = 'attachment',
}

export type PaymentSuggestion = t.TypeOf<
  typeof PaymentDetailsSuggestionDecoder
>;

export type SuggestionFields = t.TypeOf<typeof SuggestionFieldsDecoder>;
export type EntrySuggestions = t.TypeOf<typeof EntrySuggestionsDecoder>;
export type NewEntrySuggestion = t.TypeOf<typeof NewEntrySuggestionDecoder>;
export type ContactSuggestion = t.TypeOf<typeof ContactSuggestionDecoder>;
export type CategorySuggestion = t.TypeOf<typeof CategorySuggestionDecoder>;

export enum EntrySituation {
  SETTLED = 'settled',
  SCHEDULED = 'scheduled',
  PENDING_AUTHORIZATION = 'pending_authorization',
  PAID = 'paid',
  PROCESSING = 'processing',
  CANCELED = 'canceled',
  UNAUTHORIZED = 'unauthorized',
  FAILED = 'failed',
  REVERTED = 'reverted',
  PENDING = 'pending',
  WAITING_APPROVAL = 'waiting_approval',
  APPROVED = 'approved',
  REPROVED = 'reproved',
  WAITING_PAYMENT = 'waiting_payment',
  PAYMENT_SCHEDULED = 'payment_scheduled',
  DELETED = 'deleted',
}

export enum AvailableActions {
  CONCILIATE = 'conciliate',
  DELETE = 'delete',
  SEND_TO_WAITING_APPROVAL = 'send_to_waiting_approval',
  SEND_TO_APPROVED = 'send_to_approved',
  SEND_TO_REPROVED = 'send_to_reproved',
  SEND_TO_MANUAL_PAYMENT = 'send_to_manual_payment',
  SEND_TO_AUTOMATIC_PAYMENT = 'send_to_automatic_payment',
  UNDO_SEND_TO_APPROVED = 'undo_send_to_approved',
  UNDO_SEND_TO_WAITING_APPROVAL = 'undo_send_to_waiting_approval',
  UNDO_SEND_TO_WAITING_PAYMENT = 'undo_send_to_waiting_payment',
  MARK_AS_FINANCIAL_ENTRY = 'mark_as_financial_entry',
  SPLIT = 'split',
  ISSUE_NF = 'issue_nf',
  UNLINK_NF = 'unlink_nf',
  RESTORE = 'restore',
}

export type RestoreEntryPostParams = {
  organizationId: string;
  companyId: string;
  billsIds?: string[];
  receivableIds?: string[];
};

export enum EntryUnfilledContextFields {
  ASSIGNEES = 'assignees',
  ATTACHMENTS = 'attachments',
  CATEGORY_ID = 'category_id',
  CONTACT_ID = 'contact_id',
  RELEVANT_DATE = 'relevant_date',
}
