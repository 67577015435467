import t from 'zod';

import type { InsightResolver } from './validations';

export const AiInsightDecoder = t.object({
  answer: t.string(),
  question: t.string(),
  id: t.string(),
});

export type AiInsight = t.TypeOf<typeof AiInsightDecoder>;

export type UpdateInsightParams = {
  organizationId?: string;
  companyId?: string;
  insightId?: string;
  status?: string;
};

export type CreateInsightParams = {
  organizationId?: string;
  companyId?: string;
  startDate?: string;
  endDate?: string;
  userPrompt?: string;
};

export type InsightFormParams = t.TypeOf<typeof InsightResolver>;
