import { Box, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import OverviewChart from 'src/features/charts/OverviewChart';

import Totals from './Totals';
import styles from './Overview.module.scss';

export default function Overview() {
  return (
    <Box className={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap={6}
      >
        <Typography
          variant="text-lg"
          fontWeight="bold"
          component="h6"
          marginBottom={7}
        >
          Visão Geral
        </Typography>
      </Stack>

      <Totals />

      <OverviewChart data={[]} />
    </Box>
  );
}
