import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

export function SortIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="Icone de um clipe de papel"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M7 4V20M7 20L3 16M7 20L11 16M17 20V4M17 4L13 8M17 4L21 8"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
