import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import t from 'zod';

export const REQUIRED_MESSAGE_DOCUMENT = 'Por favor, informe o CNPJ.';
export const REQUIRED_MESSAGE_CLIENT_ID = 'Por favor, informe o Client ID.';
export const REQUIRED_MESSAGE_TOKEN = 'Por favor, informe o Token.';

export const ItauAutoPaymentResolver = t.object({
  document: t
    .string({
      required_error: REQUIRED_MESSAGE_DOCUMENT,
    })
    .min(14, { message: REQUIRED_MESSAGE_DOCUMENT })
    .refine((val) => val.length > 11 && cnpjValidator.isValid(val), {
      message: 'CNPJ inválido',
    }),

  clientId: t
    .string({
      required_error: REQUIRED_MESSAGE_CLIENT_ID,
    })
    .min(1, { message: REQUIRED_MESSAGE_CLIENT_ID }),

  tempToken: t
    .string({
      required_error: REQUIRED_MESSAGE_TOKEN,
    })
    .min(1, { message: REQUIRED_MESSAGE_TOKEN }),
});
