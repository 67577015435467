import { useRef, useState } from 'react';

import { Button, DialogActions, DialogContent, Stack } from '@mui/material';

import type { PortalProps } from 'src/ui';

import { useGetBills } from 'src/libs/finbits/Bills';
import { type BillPayable, BillStatus } from 'src/libs/finbits/Bills/types';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import {
  BillsApprovalsType,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import type { WaitingPaymentParams } from 'src/libs/finbits/PaymentsFlow/types';

import BankAutomationAlert from 'src/features/open-banking/BankAutomationAlert';

import AutomaticPayment from './AutomaticPayment';
import ManualPayment from './ManualPayment';
import styles from './PaymentReviewModalContent.module.scss';
import SendPaymentButton from './SendPaymentButton';

type Props = {
  selecteds: BillPayable[];
  onConfirm: (params: WaitingPaymentParams) => void;
} & Pick<PortalProps, 'onClose'>;

function splitIntoManualOrAutomatic(bills: BillPayable[]) {
  return bills.reduce<{
    manualPayments: BillPayable[];
    automaticPayments: BillPayable[];
  }>(
    (acc, bill) => {
      if (canPerformAction(bill, AvailableActions.SEND_TO_AUTOMATIC_PAYMENT)) {
        acc.automaticPayments.push(bill);
      } else {
        acc.manualPayments.push(bill);
      }

      return acc;
    },
    {
      manualPayments: [],
      automaticPayments: [],
    }
  );
}

export default function PaymentReviewModalContent({
  selecteds,
  onClose,
  onConfirm,
}: Props) {
  const containerRef = useRef<HTMLInputElement>(null);

  const [{ manualPayments, automaticPayments }, setPayments] = useState(
    splitIntoManualOrAutomatic(selecteds)
  );
  const [selectedIds, setSelectedIds] = useState(
    automaticPayments.map((bill) => bill.id)
  );

  const { companyId, organizationId } = useCompanyParams();
  const { company } = useCompany({ organizationId, companyId });
  const billsCurrentStatus =
    company?.billsApprovals === BillsApprovalsType.DISABLED
      ? BillStatus.PENDING
      : BillStatus.APPROVED;

  useGetBills(
    { companyId, organizationId, status: billsCurrentStatus },
    {
      onSuccess: (data) => {
        const initialSelectedIds = selecteds.map((bill) => bill.id);
        const { automaticPayments, manualPayments } =
          splitIntoManualOrAutomatic(
            data.filter((bill) => initialSelectedIds.includes(bill.id))
          );

        setPayments({ automaticPayments, manualPayments });
        setSelectedIds(
          selectedIds.filter((id) =>
            automaticPayments.find((bill) => bill.id === id)
          )
        );
      },
    }
  );

  function handleConfirm() {
    const automaticDeselectedIds = automaticPayments
      .filter((automatic) => !selectedIds.includes(automatic.id))
      .map((bill) => bill.id);

    const toManualIds = manualPayments.map((manual) => manual.id);

    onConfirm({
      toAutomaticIds: selectedIds,
      toManualIds: [...toManualIds, ...automaticDeselectedIds],
    });
    onClose();
  }

  const hasAutomatics = Boolean(automaticPayments.length);
  const hasManuals = Boolean(manualPayments.length);
  const isAllAutomaticsSelected =
    selectedIds.length === automaticPayments.length;
  const hasAutoPaymentsInManuals = manualPayments.some(
    (item) =>
      item.availableActions &&
      item.availableActions.includes(AvailableActions.SEND_TO_AUTOMATIC_PAYMENT)
  );

  return (
    <>
      <DialogContent dividers ref={containerRef}>
        {hasAutomatics && (
          <AutomaticPayment
            automaticPayments={automaticPayments}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        )}

        {!hasAutomatics && <BankAutomationAlert />}

        {hasManuals && (
          <Stack gap={2}>
            <ManualPayment
              manualPayments={manualPayments}
              containerRef={containerRef}
            />
          </Stack>
        )}
      </DialogContent>

      <DialogActions className={styles.dialogActions}>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>

        <SendPaymentButton
          disabled={!isAllAutomaticsSelected || hasAutoPaymentsInManuals}
          onConfirm={handleConfirm}
        />
      </DialogActions>
    </>
  );
}
