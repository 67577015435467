import type { MutableRefObject } from 'react';
import { useMemo } from 'react';

import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { Table } from 'src/mui/_scss';
import { EmptyState } from 'src/mui/_scss/Table';

import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import useEditCellValidation from 'src/features/entries/EntriesDataGrid/editCellEvents/useEditCellValidation';
import {
  ToolbarQuickActions,
  ToolbarSelectionActions,
} from 'src/features/entries/EntriesDataGrid/GridToolBar';
import useEntriesParams from 'src/features/entries/useEntriesParams';

import ContextMenuRowActions from './ContextMenuRowActions/ContextMenuRowActions';
import useHandleProcessRowUpdate from './editCellEvents/useHandleProcessRowUpdate';
import { panelQuickFilters } from './panelQuickFilters';
import { useBuildColumns } from './useBuildColumns';

type Props = {
  apiRef?: MutableRefObject<GridApiPremium>;
  classifications: Classification[];
  entries: FinancialStatementEntry[];
  loading: boolean;
  showQuickFilter?: boolean;
  quickFilter?: string[];
};

export const STORAGE_KEY = 'entries_data_grid_state_v2_';

function EmptyStateOverlay() {
  return (
    <EmptyState
      title="Sem lançamentos para exibir"
      subTitle={
        <>
          A busca ou filtro aplicado não retornou <br />
          nenhum lançamento.
        </>
      }
    />
  );
}

export default function EntriesDataGrid({
  apiRef,
  classifications,
  entries,
  loading,
  showQuickFilter = false,
}: Props) {
  const onCellEditStart = useEditCellValidation();
  const { isLoading: isSavingBatch, handleProcessRowUpdate } =
    useHandleProcessRowUpdate();

  const { isLoadingAccounts, companyId } = useEntriesParams();

  const isLoadingTable = useMemo(
    () => loading || isLoadingAccounts || isSavingBatch,
    [loading, isLoadingAccounts, isSavingBatch]
  );

  const columns = useBuildColumns(classifications);

  return (
    <Table
      apiRef={apiRef}
      checkboxSelection
      storageKey={`${STORAGE_KEY}${companyId}`}
      isLoading={isLoadingTable}
      rows={entries}
      columns={columns}
      GridToolBarSlots={{
        SelectedActions: ToolbarSelectionActions,
        QuickActions: ToolbarQuickActions,
        selectedText: ['lançamento selecionado', 'lançamentos selecionados'],
      }}
      quickFilterPanel={panelQuickFilters}
      CustomContextMenuOptions={ContextMenuRowActions}
      onProcessRowUpdate={handleProcessRowUpdate}
      onCellEditStart={onCellEditStart}
      NoRowsOverlay={EmptyStateOverlay}
      NoResultsOverlay={EmptyStateOverlay}
      showQuickFilter={showQuickFilter}
    />
  );
}
