import t from 'zod';

import {
  OpenBankingConnectionDecoder,
  OpenBankingConnectionStatusDecoder,
} from 'src/libs/finbits/Bank/OpenBanking/types';
import { BalanceDecoder } from 'src/libs/finbits/Organization/Companies/Balances/types';
import {
  INVALID_EMAIL_MESSAGE,
  required,
} from 'src/libs/finbits/resolverValidations';

export enum AccountType {
  CHECKING_ACCOUNT = 'checking_account',
  CASH_ACCOUNT = 'cash_account',
}

export enum SynchronizeType {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export const BtgScopesDecoder = t.object({
  scopeAccountsReadonly: t.boolean(),
  scopeDda: t.boolean(),
  scopePayments: t.boolean(),
});

export type BtgScopes = t.TypeOf<typeof BtgScopesDecoder>;

export const ConsentmentDecoder = t.object({
  consenterDocument: t.string(),
  consenterEmail: t.string(),
  consenterName: t.string(),
});

export type BTGConsentment = t.TypeOf<typeof ConsentmentDecoder>;

export const BtgConnectionDecoder = t
  .object({
    id: t.string(),
    userId: t.string(),
    accountExternalId: t.string(),
    organizationId: t.string(),
    ownerDocument: t.string(),
    status: OpenBankingConnectionStatusDecoder,
    consentment: ConsentmentDecoder.nullish(),
  })
  .merge(BtgScopesDecoder);

export type BtgConnection = t.TypeOf<typeof BtgConnectionDecoder>;

export const AccountDecoder = t.object({
  accountNumber: t.string().nullable(),
  bankName: t.string(),
  branchNumber: t.string().nullable(),
  id: t.string(),
  name: t.string(),
  routingNumber: t.string().nullable(),
  synchronizeType: t.union([
    t.literal(SynchronizeType.AUTOMATIC),
    t.literal(SynchronizeType.MANUAL),
  ]),
  synchronizedAt: t.string().nullable(),
  type: t.union([
    t.literal(AccountType.CHECKING_ACCOUNT),
    t.literal(AccountType.CASH_ACCOUNT),
  ]),
  initialBalance: BalanceDecoder.nullish(),
  openBankingConnection: OpenBankingConnectionDecoder.nullish(),
  firstDailyBalanceDate: t.string().nullable(),
  btgConnection: BtgConnectionDecoder.nullish(),
  canAssociatePaymentConnection: t.boolean(),
  document: t.string().nullable(),
});

export const AccountsDecoder = t.array(AccountDecoder);

export type Account = t.TypeOf<typeof AccountDecoder>;

export const DeleteAccountResolver = t
  .object({
    isCashAccount: t.boolean(),
    accountName: t.string().nullable(),
    accountNumber: t.string().nullable(),
    confirmation: required,
    backupEnabled: t.boolean(),
    sendTo: t.string().email(INVALID_EMAIL_MESSAGE).array(),
  })
  .superRefine((values, ctx) => {
    if (values.isCashAccount) {
      if (values.accountName !== values.confirmation) {
        return ctx.addIssue({
          message: 'Nome da conta não corresponde a conta selecionada',
          code: t.ZodIssueCode.custom,
          path: ['confirmation'],
        });
      }
    } else {
      if (values.accountNumber !== values.confirmation) {
        return ctx.addIssue({
          message: 'Número da conta não corresponde a conta selecionada',
          code: t.ZodIssueCode.custom,
          path: ['confirmation'],
        });
      }
    }
  });

export type DeleteAccountForm = t.TypeOf<typeof DeleteAccountResolver>;

export type GetParams = {
  companyId?: string;
  organizationId?: string;
};

export type GetAccountParams = GetParams & {
  accountId: string;
};

export type AccountFormParams = Pick<
  Account,
  | 'name'
  | 'branchNumber'
  | 'accountNumber'
  | 'routingNumber'
  | 'initialBalance'
  | 'type'
> &
  Partial<Pick<Account, 'synchronizeType'>>;

export type PostParams = GetParams & AccountFormParams;

export type PatchParams = GetParams & AccountFormParams & GetAccountParams;

export type SubmitParamsType = {
  backupEnabled: boolean;
  sendTo?: string[];
};

export type DeleteParams = GetAccountParams & SubmitParamsType;

export const AccountsPendencieDecoder = t.object({
  accounts: t.object({
    initialBalancePending: t.boolean(),
  }),
});

export type AccountsPendencies = t.TypeOf<typeof AccountsPendencieDecoder>;
