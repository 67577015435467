import { useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { useCompanyListener } from 'src/libs/finbits/Channels';
import { invalidateBillsQueries } from 'src/libs/finbits/Bills';
import { invalidateReceivablesQueries } from 'src/libs/finbits/Receivables';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { invalidateNotasFiscaisQueries } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { invalidateAccountsQueries } from 'src/libs/finbits/Bank/Accounts/Accounts';
import { invalidateInboxItemsQueries } from 'src/libs/finbits/Management/NewInboxItems';
import { invalidateClassificationsQueries } from 'src/libs/finbits/Classifications';

import { invalidateApprovalRulesQueries } from '../ApprovalRules/ApprovalRules';

function ChannelsListeners() {
  const queryClient = useQueryClient();

  const billsHandler = useCallback(() => {
    invalidateBillsQueries(queryClient);
    invalidateEntriesQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('bill_created', billsHandler);
  useCompanyListener('bill_deleted', billsHandler);
  useCompanyListener('bill_approval_requested', billsHandler);
  useCompanyListener('bill_approval_request_undone', billsHandler);
  useCompanyListener('bill_approved', billsHandler);
  useCompanyListener('bill_approval_undone', billsHandler);
  useCompanyListener('bill_reproved', billsHandler);
  useCompanyListener('bill_sent_to_payment', billsHandler);
  useCompanyListener('bill_sent_to_payment_undone', billsHandler);
  useCompanyListener('payment_status_updated', billsHandler);
  useCompanyListener('bills_approvals_updated', billsHandler);

  const approvalRulesHandler = useCallback(() => {
    invalidateBillsQueries(queryClient);
    invalidateApprovalRulesQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('approval_rule_created', approvalRulesHandler);
  useCompanyListener('approval_rule_updated', approvalRulesHandler);
  useCompanyListener('approval_rule_deleted', approvalRulesHandler);

  const receivablesHandler = useCallback(() => {
    invalidateReceivablesQueries(queryClient);
    invalidateEntriesQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('receivable_created', receivablesHandler);
  useCompanyListener('receivable_deleted', receivablesHandler);

  const entriesHandler = useCallback(() => {
    invalidateEntriesQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('statement_imported', entriesHandler);
  useCompanyListener('financial_entry_updated', entriesHandler);

  const notasFiscaisHandler = useCallback(() => {
    invalidateNotasFiscaisQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('nota_fiscal_issued', notasFiscaisHandler);
  useCompanyListener('nota_fiscal_issued_failed', notasFiscaisHandler);

  const accountHandler = useCallback(() => {
    invalidateAccountsQueries(queryClient);
    invalidateEntriesQueries(queryClient);
    invalidateBillsQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('account_deleted', accountHandler);

  const conciliationHandler = useCallback(() => {
    invalidateBillsQueries(queryClient);
    invalidateReceivablesQueries(queryClient);
    invalidateEntriesQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('financial_entry_conciliated', conciliationHandler);

  const inboxHandler = useCallback(() => {
    invalidateInboxItemsQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('boleto_updated', inboxHandler);
  useCompanyListener('inbox_item_created', inboxHandler);
  useCompanyListener('inbox_item_rejected', inboxHandler);
  useCompanyListener('inbox_item_restored', inboxHandler);
  useCompanyListener('inbox_item_accepted', inboxHandler);
  useCompanyListener('inbox_item_attachments_completed', inboxHandler);

  const classificationHandler = useCallback(() => {
    invalidateClassificationsQueries(queryClient);
  }, [queryClient]);

  useCompanyListener('classification_created', classificationHandler);
  useCompanyListener('classification_updated', classificationHandler);
  useCompanyListener('classification_activated', classificationHandler);
  useCompanyListener('classification_deactivated', classificationHandler);

  return null;
}

export default ChannelsListeners;
