import { useState } from 'react';

import type {
  AllSeriesType,
  ChartsTooltipProps,
  ZoomData,
} from '@mui/x-charts-pro';
import {
  BarPlot,
  ChartsAxisHighlight,
  ChartsClipPath,
  ChartsGrid,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  MarkPlot,
  ResponsiveChartContainerPro,
  ZoomSetup,
} from '@mui/x-charts-pro';
import { Typography } from 'src/design-system/components';
import { Box } from '@mui/material';

import { toCurrency } from 'src/libs/finbits/Money';
import { format, parseISO } from 'src/libs/finbits/Date';

import type { ChartData } from './types';
import EmptyState from './EmptyState';
import styles from './OverviewChart.module.scss';
import Legends from './Legends/Legends';

const CHART_PATH_ID = 'cashFlowChart';
const Y_AXIS_ID = 'yAxis';
const X_AXIS_ID = 'bar';

function formatDate(v: string) {
  return format(parseISO(v), 'dd/MM');
}

function formatCurrency(v: number | null) {
  return toCurrency(v ?? 0);
}

function buildSeries(isEmpty: boolean): AllSeriesType[] {
  if (isEmpty) {
    return [];
  }

  return [
    {
      type: 'line',
      curve: 'linear',
      dataKey: 'resultAmount',
      label: 'Saldo',
      color: 'black',
      valueFormatter: formatCurrency,
      showMark: true,
    },
    {
      type: 'bar',
      dataKey: 'creditAmount',
      label: 'Entradas',
      valueFormatter: formatCurrency,
      stack: 'amountBar',
      color: '#108036',
    },
    {
      type: 'bar',
      dataKey: 'debitAmount',
      label: 'Saídas',
      valueFormatter: formatCurrency,
      stack: 'amountBar',
      color: '#292929',
    },
  ];
}

const fakeEmptyData = Array.from({ length: 12 }, (item, i) =>
  format(new Date(2025, i), 'yyyy-MM-dd')
);
const fakeEmptyAmount = [10000, 1000, -1000, -10000];

type Props = {
  data: ChartData[];
};

export default function OverviewChart({ data }: Props) {
  const [zoom, setZoom] = useState<ZoomData[]>([
    {
      axisId: X_AXIS_ID,
      start: 0,
      end: 100,
    },
  ]);

  const [tooltipTrigger, setTooltipTrigger] =
    useState<ChartsTooltipProps<any>['trigger']>('item');

  function handleTooltipChange() {
    setTooltipTrigger(tooltipTrigger === 'item' ? 'axis' : 'item');
  }

  const isEmpty = data.length === 0;

  return (
    <Box className={styles.container}>
      <Typography
        variant="text-md"
        fontWeight={600}
        color="grey.600"
        marginBottom={2}
      >
        Fluxo de caixa no período
      </Typography>
      <Legends isEmpty={isEmpty} />

      <ResponsiveChartContainerPro
        margin={{ top: 40, bottom: 30, left: 100, right: 10 }}
        onZoomChange={setZoom}
        zoom={zoom}
        dataset={data}
        series={buildSeries(isEmpty)}
        xAxis={
          [
            {
              id: X_AXIS_ID,
              dataKey: 'date',
              scaleType: 'band',
              valueFormatter: formatDate,
              zoom: true,
              data: isEmpty ? fakeEmptyData : undefined,
            },
          ] as any
        }
        yAxis={[
          {
            id: Y_AXIS_ID,
            scaleType: isEmpty ? 'point' : undefined,
            valueFormatter: formatCurrency,
            data: isEmpty ? fakeEmptyAmount : undefined,
          },
        ]}
      >
        {isEmpty ? (
          <>
            <EmptyState />
            <ChartsYAxis
              position="left"
              axisId={Y_AXIS_ID}
              classes={{
                line: styles.tick,
                tick: styles.tick,
                tickLabel: styles.label,
              }}
            />
            <ChartsXAxis
              position="bottom"
              axisId={X_AXIS_ID}
              classes={{
                line: styles.tick,
                tick: styles.tick,
                tickLabel: styles.label,
              }}
            />
          </>
        ) : (
          <>
            <ChartsGrid horizontal />
            <ChartsClipPath id={CHART_PATH_ID} />
            <g clipPath={`url(#${CHART_PATH_ID})`}>
              <BarPlot onItemClick={handleTooltipChange} />
              <LinePlot skipAnimation />
              <MarkPlot skipAnimation />
            </g>

            <ChartsYAxis position="left" axisId={Y_AXIS_ID} />
            <ChartsXAxis position="bottom" axisId={X_AXIS_ID} />

            <LineHighlightPlot />
            <ChartsAxisHighlight x="band" y="line" />
            <ChartsTooltip trigger={tooltipTrigger} />

            <ZoomSetup />
          </>
        )}
      </ResponsiveChartContainerPro>
    </Box>
  );
}
