import { CircularProgress, Fade } from '@mui/material';
import { Alert, AlertTitle } from 'src/design-system/components';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { InboxItemAttachmentsStatus } from 'src/libs/finbits/Management/NewInboxItems/validations';

type Props = {
  attachmentsStatus?: InboxItemAttachmentsStatus | null;
};

export default function AttachmentsStatusAlert({ attachmentsStatus }: Props) {
  const showAlert = attachmentsStatus === InboxItemAttachmentsStatus.PENDING;

  const { isEnabled } = useExternalFeatureFlag(Feature.AI_AUTOFILL_SUGGESTIONS);

  if (!isEnabled) return null;

  return (
    <Fade appear={false} unmountOnExit in={showAlert}>
      <div>
        <Alert
          severity="default"
          icon={<CircularProgress color="secondary" size={18} />}
        >
          <AlertTitle marginBottom={0}>
            Coletando sugestões de preenchimento automático do lançamento...
          </AlertTitle>
        </Alert>
      </div>
    </Fade>
  );
}
