import { useState } from 'react';

import { Box, Button, CircularProgress, Fade } from '@mui/material';
import { Alert, AlertTitle } from 'src/design-system/components';
import { SparklesIcon } from 'src/mui/_icons';

import { InboxItemAttachmentsStatus } from 'src/libs/finbits/Management/NewInboxItems/validations';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';

import { useAutofillSuggestionsContext } from 'src/features/bills-to-pay/BillContent/autofill-suggestions/AutofillSuggestionsProvider';
import { useEntrySuggestionsContext } from 'src/features/entries/Suggestions/EntrySuggestionsProvider';

import styles from './InboxAttachmentsStatusAlert.module.scss';

export default function InboxAttachmentsStatusAlert() {
  const { isEnabled } = useExternalFeatureFlag(Feature.AI_AUTOFILL_SUGGESTIONS);

  const { updateSuggestionSelected } = useEntrySuggestionsContext();
  const { inboxAttachmentsStatus, applySuggestion, suggestionApplied } =
    useAutofillSuggestionsContext();
  const [initialStatus] = useState(inboxAttachmentsStatus);

  const isPending =
    inboxAttachmentsStatus === InboxItemAttachmentsStatus.PENDING;

  const showAlert =
    initialStatus === InboxItemAttachmentsStatus.PENDING && !suggestionApplied;

  if (!isEnabled) return null;

  function handleContactSuggestion(data: BillPayableOptional) {
    updateSuggestionSelected({
      contact: {
        type: data.contact?.type as ContactType | 'international',
        document: data.contact?.document || '',
      },
    });
  }

  return (
    <Fade in={showAlert} mountOnEnter unmountOnExit appear={false}>
      <Box marginBottom={6}>
        <Alert
          severity="default"
          icon={
            isPending ? (
              <CircularProgress color="secondary" size={18} />
            ) : (
              <SparklesIcon color="success" />
            )
          }
          classes={{
            action: styles.action,
            root: styles.alert,
          }}
          action={
            !isPending && (
              <Button
                variant="text"
                color="primary"
                onClick={() => applySuggestion(handleContactSuggestion)}
              >
                Aplicar todas
              </Button>
            )
          }
        >
          <AlertTitle marginBottom={0}>
            {isPending
              ? 'Coletando sugestões de preenchimento automático do lançamento...'
              : 'Novas sugestões disponíveis.'}
          </AlertTitle>
        </Alert>
      </Box>
    </Fade>
  );
}
