import type { FC, ReactNode } from 'react';
import React, { isValidElement, useEffect, useRef, useState } from 'react';

import { Box, Button, Fade } from '@mui/material';
import cn from 'classnames';

import styles from './BannerCarousel.module.scss';

type Props = {
  autoplay?: boolean;
  randomInitialSlide?: boolean;
  showBullets?: boolean;
  autoPlaySpeed?: number;
  children: ReactNode;
};

export default function BannerCarousel({
  autoplay = false,
  randomInitialSlide = false,
  showBullets = false,
  autoPlaySpeed = 5000,
  children,
}: Props) {
  const activeSlideRef = useRef<HTMLDivElement | null>(null);
  const autoplayRef = useRef<NodeJS.Timeout | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number | undefined>(
    undefined
  );

  const slides = React.Children.toArray(children);

  const filteredSlides = slides.filter((slide) => {
    if (!isValidElement(slide)) return false;

    const renderedOutput =
      typeof slide.type === 'function'
        ? (slide.type as FC<any>)(slide.props)
        : slide.type;

    return renderedOutput !== null && renderedOutput !== undefined;
  });

  const slidesLength = filteredSlides.length;

  function updateContainerHeight() {
    if (activeSlideRef.current) {
      const height = activeSlideRef.current.offsetHeight;
      if (height > 0) {
        setContainerHeight(height);
      }
    }
  }

  function startAutoplay() {
    if (autoplay && !!slidesLength) {
      autoplayRef.current = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slidesLength);
      }, autoPlaySpeed);
    }
  }

  function stopAutoplay() {
    if (autoplayRef.current) {
      clearInterval(autoplayRef.current);
    }
  }

  function handleBulletClick(index: number) {
    stopAutoplay();
    setCurrentSlide(index);
    startAutoplay();
  }

  useEffect(() => {
    if (randomInitialSlide && !!slidesLength) {
      const randomIndex = Math.floor(Math.random() * slidesLength);
      setCurrentSlide(randomIndex);
    }
    setTimeout(updateContainerHeight, 0);
  }, [randomInitialSlide, slidesLength]);

  useEffect(() => {
    startAutoplay();
    return () => stopAutoplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, slidesLength, autoPlaySpeed]);

  useEffect(() => {
    updateContainerHeight();
  }, [currentSlide]);

  return (
    <Box position="relative">
      <Box
        className={styles.container}
        height={containerHeight ? `${containerHeight}px` : 'auto'}
      >
        {filteredSlides.map((slide, index) => (
          <Fade
            key={index}
            in={currentSlide === index}
            timeout={{ enter: 500, exit: 500 }}
            mountOnEnter
            unmountOnExit
          >
            <Box
              ref={currentSlide === index ? activeSlideRef : null}
              width="100%"
              data-slide-index={index}
              role="presentation"
            >
              {slide}
            </Box>
          </Fade>
        ))}
      </Box>

      {showBullets && slidesLength > 1 && (
        <Box className={styles.listBullets}>
          {filteredSlides.map((_, index) => (
            <Button
              key={index}
              onClick={() => handleBulletClick(index)}
              className={cn(styles.bullet, {
                [styles.active]: currentSlide === index,
              })}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
