import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Collapse, Drawer, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { Typography } from 'src/design-system/components';
import { RefreshCW05Icon, SparklesIcon } from 'src/mui/_icons';

import {
  NavigateAction,
  useExecuteNavigateAction,
} from 'src/router/useNavigateAction/useNavigateAction';

import { IconButton } from 'src/mui';

import FinBot from 'src/pages/Organizations/Companies/FinBot';

import analytics from 'src/libs/analytics';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import { format } from 'src/libs/finbits/Date';
import { useFinancialStatementEntries } from 'src/libs/finbits/Management/FinancialStatements/Entries/Entries';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { ConnectionProviderType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import EntriesDataGrid from 'src/features/entries/EntriesDataGrid';
import EntriesSummary from 'src/features/entries/EntriesSummary/EntriesSummary';
import useEntriesParams from 'src/features/entries/useEntriesParams';

import ToggleSwitch from '../ToggleSwitch';

import ButtonActions from './ButtonActions';
import CreateEntriesDropdown from './CreateEntriesDropdown';
import styles from './EntriesStatement.module.scss';

type Props = { classifications: Classification[] };

export default function EntriesStatement({ classifications }: Props) {
  const [hasPluggy, setHasPluggy] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const apiRef = useGridApiRef();

  const { organizationId, companyId, selectedAccountIds, selectedDateRange } =
    useEntriesParams();

  const {
    data,
    isLoading: isLoadingEntries,
    isFetching: isFetchingEntries,
  } = useFinancialStatementEntries({
    organizationId,
    companyId,
    accountsIds: selectedAccountIds,
    startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
    endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
    groupSplits: false,
  });

  const isDataGridLoading = useMemo(
    () => isFetchingEntries || isLoadingEntries,
    [isLoadingEntries, isFetchingEntries]
  );

  useEffect(() => {
    if (!!data && !!data.accounts) {
      setHasPluggy(
        data.accounts.some(
          ({ connectionProvider }) =>
            connectionProvider === ConnectionProviderType.PLUGGY
        )
      );
    }
  }, [data]);

  const entries: FinancialStatementEntry[] = useMemo(() => {
    return data?.entries ?? [];
  }, [data]);

  const [isOpenFinBot, setOpenFinBot] = useState(false);

  function handleToggleSwitch(value: boolean) {
    setShowSummary(value);
  }

  function handleFinbotButtonClick() {
    analytics.track('FinBot Button Clicked', { company_id: companyId });
    setOpenFinBot(true);
  }

  const openFinbot = useCallback(() => setOpenFinBot(true), [setOpenFinBot]);

  useExecuteNavigateAction(openFinbot, NavigateAction.OPEN_FINBOT);

  return (
    <Box
      paddingBottom={4}
      paddingTop={6}
      paddingX={12}
      display="flex"
      flexDirection="column"
      className={styles.container}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap={6}
      >
        <Stack justifyContent="space-between" gap={4}>
          <Typography variant="text-lg" fontWeight="bold" component="h6">
            Lançamentos
          </Typography>
        </Stack>

        <Stack direction="row" gap={2}>
          {hasPluggy && (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              paddingRight={8}
            >
              <IconButton size="medium" className={styles.iconButton}>
                <RefreshCW05Icon className={styles.cachedIcon} />
              </IconButton>

              <Typography variant="text-xs">
                Lançamentos de contas automáticas que não são do Banco BTG{' '}
                <br /> podem levar até 24 horas para serem importados.
              </Typography>
            </Stack>
          )}

          <Button
            variant="outlined"
            startIcon={<SparklesIcon />}
            onClick={handleFinbotButtonClick}
          >
            FinBot
          </Button>

          <CreateEntriesDropdown />

          <ButtonActions apiRef={apiRef} />
        </Stack>
      </Stack>

      <Box display="flex" flex="1" overflow="hidden" position="relative">
        <ToggleSwitch onChange={handleToggleSwitch} />

        <EntriesDataGrid
          apiRef={apiRef}
          showQuickFilter={true}
          entries={entries}
          classifications={classifications}
          loading={isDataGridLoading}
        />

        <Collapse
          orientation="horizontal"
          in={showSummary}
          className={styles.collapse}
        >
          {!!data && (
            <EntriesSummary
              data={data}
              selectedStartDate={selectedDateRange[0]}
            />
          )}
        </Collapse>
      </Box>

      <Drawer
        classes={{ paper: styles.drawer }}
        anchor="right"
        open={isOpenFinBot}
        onClose={() => setOpenFinBot(false)}
      >
        <FinBot isPage={false} />
      </Drawer>
    </Box>
  );
}
