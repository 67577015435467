import { useState } from 'react';

import { Button, Popover, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import styles from './SendPaymentButton.module.scss';

type Props = {
  onConfirm: () => void;
  disabled?: boolean;
};

export default function SendPaymentButton({ onConfirm, disabled }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [disabledButton, setDisabledButton] = useState(false);

  const isOpen = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    if (disabled) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setDisabledButton(true);

      return;
    }

    onConfirm();
  }

  function handleClosePopover() {
    setAnchorEl(null);
    setDisabledButton(false);
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={disabledButton}
        aria-label="Enviar para pagamento"
        className={styles.primaryButton}
      >
        Enviar para pagamento
      </Button>

      <Popover
        onClose={handleClosePopover}
        elevation={8}
        anchorEl={anchorEl}
        classes={{ paper: styles.popoverRoot }}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Typography color="grey.900" fontWeight={600} variant="text-lg">
          Por favor, confirme a sua escolha.
        </Typography>

        <Typography color="grey.600" variant="text-sm" marginTop={2}>
          Você está optando por <strong>realizar de forma manual</strong> alguns
          pagamentos que poderiam ser automáticos.
        </Typography>

        <Typography color="grey.600" variant="text-sm" marginTop={2}>
          Eles ficarão com o status{' '}
          <strong>“Aguardando pagamento manual”</strong> até que sejam feitos
          manualmente.
        </Typography>

        <Stack
          marginTop={6}
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
        >
          <Button
            variant="outlined"
            onClick={handleClosePopover}
            className={styles.button}
          >
            Revisar itens
          </Button>

          <Button
            variant="contained"
            color="error"
            className={styles.button}
            onClick={onConfirm}
          >
            Confirmar
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
