import {
  type GridCellParams,
  type GridColDef,
  GridFilterInputValue,
  type GridFilterItem,
  type GridFilterOperator,
} from '@mui/x-data-grid-premium';

export const notEqualFilterOperator: GridFilterOperator = {
  label: 'diferente de',
  value: 'notEquals',
  getApplyFilterFn: (
    filterItem: GridFilterItem,
    _column: GridColDef
  ): ((value: GridCellParams) => boolean) | null => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (value: GridCellParams): boolean => value !== filterItem.value;
  },
  InputComponent: GridFilterInputValue,
};
