import { Stack } from '@mui/material';

import TotalCard from './TotalCard';

export default function Totals() {
  return (
    <Stack direction="row" gap={6}>
      <TotalCard title="Recebimentos Realizados" value={0} iconDirection="up" />
      <TotalCard title="Pagamentos Realizados" value={0} iconDirection="down" />
      <TotalCard title="Resultado do período" value={0} />
    </Stack>
  );
}
