import { useMutation, useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import { AiInsightDecoder } from './types';
import type {
  AiInsight,
  CreateInsightParams,
  UpdateInsightParams,
} from './types';

const aiKeys = {
  all: ['finbot'],
  post: (params: unknown) => [...aiKeys.all, params],
};

async function updateInsight({
  organizationId,
  companyId,
  insightId,
  ...params
}: UpdateInsightParams) {
  await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/ai/insights/${insightId}`,
    params
  );
}

export function useUpdateInsight() {
  const { mutate: updateInsightMutation, ...rest } = useMutation<
    unknown,
    ApiError,
    UpdateInsightParams
  >(updateInsight);

  return { updateInsight: updateInsightMutation, ...rest };
}

async function createInsight({
  organizationId,
  companyId,
  ...params
}: CreateInsightParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/ai/insights`,
    params
  );

  return decodeResponse<AiInsight>(response, AiInsightDecoder);
}

export function useCreateInsight(params: CreateInsightParams) {
  const hasAllParams =
    !!params.companyId &&
    !!params.organizationId &&
    !!params.userPrompt &&
    !!params.endDate &&
    !!params.startDate;

  const { data, ...rest } = useQuery<AiInsight, ApiError>({
    enabled: hasAllParams,
    queryKey: aiKeys.post(params),
    queryFn: () => createInsight(params),
  });

  return { data, ...rest };
}
