import { useDrawingArea } from '@mui/x-charts-pro';

import Img from 'src/assets/empty-chart.svg';

import styles from './EmptyState.module.scss';

const HALF_SVG_WIDTH = 200;
const HALF_SVG_HEIGHT = 200;

export default function EmptyState() {
  const { width, height } = useDrawingArea();

  const offsetX = width / 2 - HALF_SVG_WIDTH;
  const offsetY = height / 2 - HALF_SVG_HEIGHT;

  return (
    <g transform={`translate(${offsetX} ${offsetY})`}>
      <svg width="400" height="400">
        <image xlinkHref={Img} x="125" y="100" className={styles.img} />
        <text x="100" y="220" textAnchor="start" className={styles.emptyLegend}>
          Sem dados para exibir
        </text>
        <text
          x="100"
          y="230"
          textAnchor="start"
          className={styles.emptyDescription}
        >
          <tspan x="50" dy="24px">
            A busca ou filtro aplicado não retornou
          </tspan>
          <tspan x="80" dy="24px">
            nenhum dado a ser visualizado.
          </tspan>
        </text>
      </svg>
    </g>
  );
}
