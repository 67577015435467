import { Card, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';

import { toCurrency } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './TotalCard.module.scss';

type Props = {
  title: string;
  value: number;
  iconDirection?: 'up' | 'down';
  valueType?: BalanceType;
};

export default function TotalCard({
  title,
  value,
  iconDirection,
  valueType = BalanceType.CREDIT,
}: Props) {
  return (
    <Card variant="outlined" className={styles.totalCard}>
      <Stack direction="row" gap={2}>
        <Typography fontWeight={600} className={styles.totalTitle}>
          {title}
        </Typography>
        {iconDirection === 'up' && (
          <ArrowCircleUpIcon className={styles.upIcon} />
        )}
        {iconDirection === 'down' && <ArrowCircleDownIcon />}
      </Stack>
      <Typography fontWeight={600} variant="text-lg">
        {toCurrency(value, valueType)}
      </Typography>
    </Card>
  );
}
