import { useState } from 'react';

import { Button, List, Switch } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  PaperClipCheckIcon,
  PaperClipQuestionMarkIcon,
  SortIcon,
  SortOffIcon,
} from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { Tooltip, snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { useUpdateCategory } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import EditCategoryModal from 'src/features/categories/EditCategoryModal';

import styles from './CategoryItem.module.scss';

type Props = {
  category: Category;
};

function CategoryItem({ category }: Props) {
  const openPortal = useOpenPortal();

  const { organizationId, companyId } = useCompanyParams();

  const [checked, setChecked] = useState(category.active);
  const [loading, setLoading] = useState(false);

  const { updateCategory } = useUpdateCategory();

  const { isEnabled: isOverviewEnabled } = useExternalFeatureFlag(
    Feature.COMPANY_OVERVIEW
  );

  function onChange() {
    setLoading(true);
    setChecked((prevChecked) => !prevChecked);

    const payload = {
      active: !category.active,
    };

    updateCategory(
      { categoryId: category.id, companyId, organizationId, payload },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: payload.active
              ? 'Categoria ativada!'
              : 'Categoria desativada!',
          });
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
          setChecked(category.active);

          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao modificar a categoria.',
          });
        },
      }
    );
  }

  function handleOpenEditCategoryModal() {
    openPortal(EditCategoryModal, {
      category,
    });
  }

  return (
    <List.Item>
      <div className={styles.info}>
        {category.type === BalanceType.CREDIT ? (
          <ArrowUpOutlined className={styles.iconUp} />
        ) : (
          <ArrowDownOutlined className={styles.iconDown} />
        )}
        <span className={styles.title}>{category.name}</span>
      </div>
      <div className={styles.actions}>
        {isOverviewEnabled && category.optionalAttachments && (
          <Tooltip
            placement="top"
            title="Lançamentos com esta categoria dispensam a obrigatoriedade de anexos."
          >
            <PaperClipQuestionMarkIcon
              className={styles.iconEnabled}
              color="inherit"
            />
          </Tooltip>
        )}
        {isOverviewEnabled && !category.optionalAttachments && (
          <Tooltip
            placement="top"
            title="Anexos são obrigatórios em lançamentos desta categoria."
          >
            <PaperClipCheckIcon
              className={styles.iconDisabled}
              color="inherit"
            />
          </Tooltip>
        )}
        {!isOverviewEnabled && category.optionalAttachments && (
          <Tooltip title="Lançamentos com esta categoria dispensam a obrigatoriedade de anexo">
            <InfoCircleOutlined />
          </Tooltip>
        )}

        {isOverviewEnabled && category.includeInCashFlow && (
          <Tooltip
            placement="top"
            title="Saldo e fluxo de caixa são sensibilizados por lançamentos com esta categoria."
          >
            <SortIcon
              aria-label="Icone sensibiliza o saldo ou fluxo de caixa"
              className={styles.iconDisabled}
            />
          </Tooltip>
        )}
        {isOverviewEnabled && !category.includeInCashFlow && (
          <Tooltip
            placement="top"
            title={
              <>
                Lançamentos com esta
                <br /> categoria{' '}
                <Typography
                  color="warning.300"
                  component="span"
                  fontWeight="600"
                  variant="text-xs"
                >
                  não sensibilizam
                </Typography>
                <br />o saldo ou fluxo de caixa.
              </>
            }
          >
            <SortOffIcon
              aria-label="Icone não sensibiliza o saldo ou fluxo de caixa"
              className={styles.iconEnabled}
            />
          </Tooltip>
        )}

        <WithAllowedPermission
          permissions={{
            resource: 'categories',
            action: 'update',
          }}
        >
          <>
            <Button size="large" onClick={handleOpenEditCategoryModal}>
              <EditOutlined />
            </Button>
            <Tooltip
              title={category.active ? 'Desativar' : 'Ativar'}
              placement="bottom"
            >
              <Switch checked={checked} loading={loading} onChange={onChange} />
            </Tooltip>
          </>
        </WithAllowedPermission>
      </div>
    </List.Item>
  );
}

export default CategoryItem;
