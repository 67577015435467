import type { MutableRefObject } from 'react';
import { useMemo } from 'react';

import type {
  GridColDef,
  GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { Table } from 'src/mui/_scss';
import type { ProcessRowUpdateProps, TableProps } from 'src/mui/_scss/Table';
import { actionColumn, checkboxColumn } from 'src/mui/_scss/Table';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useClassifications } from 'src/libs/finbits/Classifications';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { useUpdateEntriesInBatch } from 'src/libs/finbits/Management/EntriesBatchUpdate';
import { isOverDue } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import EmptyState from 'src/features/bills-to-pay/BillsToPayDataGrid/EmptyState';
import { accountColumn } from 'src/features/entries/EntriesDataGrid/columns/accountColumn/accountColumn';
import { amountColumn } from 'src/features/entries/EntriesDataGrid/columns/amountColumn/amountColumn';
import { approvalTypeColumn } from 'src/features/entries/EntriesDataGrid/columns/approvalTypeColumn/approvalTypeColumn';
import { assigneesColumn } from 'src/features/entries/EntriesDataGrid/columns/assigneesColumn/assigneesColumn';
import { attachmentColumn } from 'src/features/entries/EntriesDataGrid/columns/attachmentColumn/attachmentColumn';
import { categoryColumn } from 'src/features/entries/EntriesDataGrid/columns/categoryColumn/categoryColumn';
import { classificationsColumn } from 'src/features/entries/EntriesDataGrid/columns/classificationColumn/classificationColumn';
import { commentsColumn } from 'src/features/entries/EntriesDataGrid/columns/commentsColumn/commentsColumn';
import { contactColumn } from 'src/features/entries/EntriesDataGrid/columns/contactColumn/contactColumn';
import { currentSituationColumn } from 'src/features/entries/EntriesDataGrid/columns/currentSituationColumn/currentSituationColumn';
import { dateColumn } from 'src/features/entries/EntriesDataGrid/columns/dateColumn/dateColumn';
import { descriptionColumn } from 'src/features/entries/EntriesDataGrid/columns/descriptionColumn/descriptionColumn';
import { dueDateColumn } from 'src/features/entries/EntriesDataGrid/columns/dueDateColumn/dueDateColumn';
import { notaFiscalIssueDateColumn } from 'src/features/entries/EntriesDataGrid/columns/notaFiscalIssueDateColumn/notaFiscalIssueDateColumn';
import { notaFiscalNumberColumn } from 'src/features/entries/EntriesDataGrid/columns/notaFiscalNumberColumn/notaFiscalNumberColumn';
import { paymentMethodColumn } from 'src/features/entries/EntriesDataGrid/columns/paymentMethodColumn/paymentMethodColumn';
import { relevantdateColumn } from 'src/features/entries/EntriesDataGrid/columns/relevantDateColumn/relevantDateColumn';
import { requestedAtColumn } from 'src/features/entries/EntriesDataGrid/columns/requestedAtColumn/requestedAtColumn';
import { requesterColumn } from 'src/features/entries/EntriesDataGrid/columns/requesterColumn/requesterColumn';
import useEditCellValidation from 'src/features/entries/EntriesDataGrid/editCellEvents/useEditCellValidation';

import styles from './BillsToPayDataGrid.module.scss';
import ContextMenuRowActions from './ContextMenuRowActions/ContextMenuRowActions';
import { RowActions } from './ContextMenuRowActions/RowActions/RowActions';
import { ToolbarSelectedActions, TotalSum } from './GridToolbar';
import { panelQuickFilters } from './panelQuickFilters';

type Props = {
  apiRef?: MutableRefObject<GridApiPremium>;
  bills: BillPayable[];
  isLoading?: boolean;
  billStatus?: BillStatus;
  editable?: boolean;
} & Pick<
  TableProps<BillPayable>,
  | 'GridToolBar'
  | 'FooterSlots'
  | 'density'
  | 'checkboxSelection'
  | 'RowSelection'
  | 'storageKey'
  | 'hideFooter'
  | 'pageSize'
  | 'disableMultipleRowSelection'
  | 'GridToolBarSlots'
>;

function getRowClassName(params: GridRowClassNameParams<BillPayable>) {
  if (isOverDue(params.row.date)) {
    return styles.highlightRow;
  }
  return '';
}

function concatApprovalColumns({
  defaultColumns,
  billStatus,
  canEdit = false,
}: {
  defaultColumns: GridColDef[];
  billStatus?: BillStatus;
  canEdit?: boolean;
}) {
  const paymentDetailsIndex = defaultColumns.findIndex(
    (column) => column.field === 'paymentDetails'
  );

  if (billStatus === BillStatus.PENDING) {
    defaultColumns.splice(
      paymentDetailsIndex,
      0,
      assigneesColumn({ editable: canEdit }),
      approvalTypeColumn({ editable: canEdit })
    );

    return defaultColumns;
  }

  defaultColumns.splice(
    paymentDetailsIndex,
    0,
    assigneesColumn({ editable: canEdit }),
    approvalTypeColumn({ editable: canEdit }),
    requestedAtColumn(),
    requesterColumn()
  );

  return defaultColumns;
}

function concatNewBillFields({
  defaultColumns,
  canEdit = false,
}: {
  defaultColumns: GridColDef[];
  canEdit?: boolean;
}) {
  defaultColumns.push(
    notaFiscalNumberColumn({ editable: canEdit }),
    notaFiscalIssueDateColumn({ editable: canEdit }),
    dueDateColumn({ editable: canEdit })
  );

  return defaultColumns;
}

export const STORAGE_KEY = 'bills_data_grid_state_';

export default function BillsToPayDataGrid({
  apiRef,
  bills,
  isLoading = false,
  billStatus,
  GridToolBar,
  FooterSlots,
  density,
  checkboxSelection = true,
  RowSelection,
  storageKey,
  hideFooter,
  pageSize,
  disableMultipleRowSelection,
  GridToolBarSlots: GridToolBarSlotsProps,
  editable = true,
}: Props) {
  const { organizationId, companyId } = useCompanyParams();
  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();
  const { isEnabled: isNewBillFieldsEnabled } = useExternalFeatureFlag(
    Feature.NEW_BILL_FIELDS
  );
  const onCellEditStart = useEditCellValidation<BillPayable>();

  const { classifications, isLoading: isLoadingClassifications } =
    useClassifications(
      { companyId, organizationId },
      { staleTime: FIVE_MINUTES_IN_MS }
    );

  const { mutateAsync: updateEntries, isLoading: isUpdating } =
    useUpdateEntriesInBatch();

  function handleProcessRowUpdate({
    fields,
    activeRows,
  }: ProcessRowUpdateProps<BillPayable>) {
    return updateEntries({
      billsIds: activeRows.map((row) => row.id),
      organizationId,
      companyId,
      changes: fields,
    });
  }

  const canEdit =
    useAllowedPermission({
      action: 'batch_update',
      resource: 'entries',
    }) && editable;

  const columns: GridColDef[] = useMemo(() => {
    const defaultColumns = [
      checkboxColumn(),
      dateColumn({ headerName: 'Data de pagamento', editable: canEdit }),
      descriptionColumn({ editable: canEdit }),
      accountColumn({ editable: canEdit }),
      currentSituationColumn({ editable: canEdit }),
      categoryColumn({ editable: canEdit }),
      contactColumn({ editable: canEdit }),
      relevantdateColumn({ editable: canEdit }),
      paymentMethodColumn({ editable: canEdit }),
      ...classificationsColumn({
        classifications,
        columnProps: { editable: canEdit },
      }),
      amountColumn({ editable: canEdit }),
      attachmentColumn(),
      commentsColumn({ editable: canEdit }),
      actionColumn({ getActions: RowActions }),
    ];

    if (isNewBillFieldsEnabled) {
      concatNewBillFields({ defaultColumns, canEdit });
    }

    if (isEnabledBillsApprovals) {
      return concatApprovalColumns({ defaultColumns, billStatus, canEdit });
    }

    return defaultColumns;
  }, [
    classifications,
    canEdit,
    isEnabledBillsApprovals,
    isNewBillFieldsEnabled,
    billStatus,
  ]);

  const GridToolBarSlots = useMemo(() => {
    if (Boolean(GridToolBarSlotsProps)) return GridToolBarSlotsProps;

    return {
      SelectedActions: ToolbarSelectedActions,
      QuickActions: () => <TotalSum bills={bills} />,
      selectedText: ['lançamento selecionado', 'lançamentos selecionados'] as [
        string,
        string
      ],
    };
  }, [bills, GridToolBarSlotsProps]);

  const isTableLoading = isUpdating || isLoading || isLoadingClassifications;

  return (
    <Table
      apiRef={apiRef}
      storageKey={storageKey}
      rows={bills ?? []}
      columns={columns}
      isLoading={isTableLoading}
      checkboxSelection={checkboxSelection}
      CustomContextMenuOptions={ContextMenuRowActions}
      GridToolBar={GridToolBar}
      GridToolBarSlots={GridToolBarSlots}
      onProcessRowUpdate={handleProcessRowUpdate}
      getRowClassName={getRowClassName}
      NoRowsOverlay={EmptyState}
      NoResultsOverlay={EmptyState}
      onCellEditStart={onCellEditStart}
      quickFilterPanel={
        billStatus !== BillStatus.REPROVED ? panelQuickFilters : []
      }
      density={density}
      RowSelection={RowSelection}
      hideFooter={hideFooter}
      pageSize={pageSize}
      FooterSlots={FooterSlots}
      disableMultipleRowSelection={disableMultipleRowSelection}
      showQuickFilter
      key={billStatus}
    />
  );
}
