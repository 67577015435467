import { useCallback, useEffect, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import { getItem, setItem } from 'src/libs/finbits/Storage';
import {
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
} from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

type Storage = {
  startDate?: Date;
  endDate?: Date;
  accountIds?: string[];
};

const defaultStoredValue = {
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
  accountIds: [],
};

export function buildStorageKey(companyId: string) {
  return `entries_filters:${companyId}`;
}

export function toSearchParams(accountIds: string[], rangeDate: [Date, Date]) {
  const [startDate, endDate] = rangeDate;
  const params = new URLSearchParams(window.location.search.slice(1));

  params.delete('accountId');
  params.delete('startDate');
  params.delete('endDate');

  accountIds.forEach((id: string) => params.append('accountId', id));
  params.append('startDate', format(startDate, 'yyyy-MM-dd'));
  params.append('endDate', format(endDate, 'yyyy-MM-dd'));

  return params;
}

function parseDate(
  value: Date | string | null | undefined,
  defaultValue: Date
) {
  if (!value) return defaultValue;

  return typeof value === 'string' ? parseISO(value) : value;
}

export default function useEntriesParams() {
  const { organizationId, companyId } = useCompanyParams();

  const { accounts, isLoading: isLoadingAccounts } = useAccounts(
    { organizationId, companyId },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDateRange = useMemo<[Date, Date]>(() => {
    const startDateParam = searchParams.get('startDate');
    const endDateParam = searchParams.get('endDate');

    const storedFilters = getItem<Storage>(
      buildStorageKey(companyId),
      defaultStoredValue
    );

    const startDate = startDateParam ?? storedFilters.startDate;
    const endDate = endDateParam ?? storedFilters.endDate;

    return [
      parseDate(startDate, startOfMonth(new Date())),
      parseDate(endDate, endOfMonth(new Date())),
    ];
  }, [searchParams, companyId]);

  const selectedAccountIds = useMemo<string[]>(() => {
    const accountIdsdParam = searchParams.getAll('accountId');

    const storedFilters = getItem<Storage>(
      buildStorageKey(companyId),
      defaultStoredValue
    );

    return accountIdsdParam.length
      ? accountIdsdParam
      : storedFilters.accountIds ?? [];
  }, [searchParams, companyId]);

  const selectedAccounts = useMemo(() => {
    return accounts.filter((account) =>
      selectedAccountIds.includes(account.id)
    );
  }, [accounts, selectedAccountIds]);

  const persistInStorage = useCallback(
    (filters: Storage) => {
      setItem(buildStorageKey(companyId), filters);
    },
    [companyId]
  );

  const setOnUrl = useCallback(
    (accountIds: string[], selectedDateRange: [Date, Date]) => {
      const params = toSearchParams(accountIds, selectedDateRange);

      setSearchParams(params);
    },
    [setSearchParams]
  );

  const handleSetSelectedAccountIds = useCallback(
    (accountIds: string[]) => {
      persistInStorage({
        accountIds,
        startDate: selectedDateRange[0],
        endDate: selectedDateRange[1],
      });
      setOnUrl(accountIds, selectedDateRange);
    },
    [selectedDateRange, persistInStorage, setOnUrl]
  );

  const handleSetSelectedDateRange = useCallback(
    (dates: [Date, Date]) => {
      if (!dates) return;

      const [startDate, endDate] = dates;

      persistInStorage({ accountIds: selectedAccountIds, startDate, endDate });
      setOnUrl(selectedAccountIds, [startDate, endDate]);
    },
    [selectedAccountIds, persistInStorage, setOnUrl]
  );

  useEffect(() => {
    setOnUrl(selectedAccountIds, selectedDateRange);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoadingAccounts) return;

    const storedFilters = getItem<Storage>(buildStorageKey(companyId));
    if (storedFilters === null) {
      handleSetSelectedAccountIds(accounts.map(({ id }) => id));
    }
  }, [
    accounts,
    isLoadingAccounts,
    selectedAccountIds,
    handleSetSelectedAccountIds,
    companyId,
  ]);

  return {
    organizationId,
    companyId,
    accounts,
    selectedAccounts,
    selectedAccountIds,
    isLoadingAccounts,
    selectedDateRange,
    setSelectedDateRange: handleSetSelectedDateRange,
    setSelectedAccountIds: handleSetSelectedAccountIds,
  };
}
