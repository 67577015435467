import classNames from 'classnames';
import { Typography } from 'src/design-system/components';
import { Stack } from '@mui/material';
import { DotIcon } from 'src/mui/_icons';

import styles from './Legends.module.scss';

type Props = {
  iconProps: {
    className: string;
    stroke?: string;
  };
  labelText: string;
  isEmpty: boolean;
};

export default function LegendItem({ isEmpty, iconProps, labelText }: Props) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <DotIcon
        fontSize="xsmall"
        {...iconProps}
        className={classNames(iconProps.className, {
          [styles.emptyLegend]: isEmpty,
        })}
      />
      <Typography variant="text-sm" fontWeight={400}>
        {labelText}
      </Typography>
    </Stack>
  );
}
