import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

export function PaperClipCheckIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="Icone de um clipe de papel com um check"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M17 9.66978L9.97925 16.7862C8.38265 18.4046 5.79405 18.4046 4.19745 16.7862C2.60085 15.1679 2.60085 12.544 4.19745 10.9256L11.2182 3.80918C12.2826 2.73027 14.0083 2.73027 15.0727 3.80918C16.1371 4.88809 16.1371 6.63734 15.0727 7.71625L8.3273 14.5536C7.7951 15.0931 6.93224 15.0931 6.40004 14.5536C5.86784 14.0142 5.86784 13.1395 6.40004 12.6001L12.3195 6.59994"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 19L16.5 21L18.75 18.75L21 16.5"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
