import type { ComponentType, SVGProps } from 'react';

import cn from 'classnames';
import { BankIcon as BankIconSvg, WalletIcon } from 'src/mui/_icons';

import styles from './BankIcon.module.scss';
import {
  BB,
  BS2,
  BTG,
  BV,
  Bradesco,
  C6,
  Caixa,
  Cora,
  Inter,
  Itau,
  Iugu,
  Nubank,
  Santander,
  Sicoob,
  Warren,
  XP,
} from './icons';
import {
  BTG as BTGMonochrome,
  Itau as ItauMonochrome,
  Santander as SantanderMonochrome,
} from './icons-monochrome';

type BankDataMap = {
  [key: string]: {
    src: string;
    alt: string;
    icon?: ComponentType<SVGProps<SVGSVGElement>>;
  };
};

type Props = {
  routingNumber?: string | null;
  width?: number;
  height?: number;
  monochrome?: boolean;
  className?: string;
};

const bankDataMap: BankDataMap = {
  '001': { src: BB, alt: 'Banco do Brasil logo' },
  '033': { src: Santander, alt: 'Santander logo' },
  '077': { src: Inter, alt: 'Banco Inter logo' },
  '104': { src: Caixa, alt: 'Caixa Economica logo' },
  '237': { src: Bradesco, alt: 'Bradesco logo' },
  '260': { src: Nubank, alt: 'Nubank logo' },
  '341': { src: Itau, alt: 'Itaú logo' },
  '401': { src: Iugu, alt: 'Iugu logo' },
  '655': { src: BV, alt: 'BV logo' },
  '208': { src: BTG, alt: 'BTG logo' },
  '218': { src: BS2, alt: 'BS2 logo' },
  '403': { src: Cora, alt: 'Cora logo' },
  '756': { src: Sicoob, alt: 'Sicoob logo' },
  '102': { src: XP, alt: 'XP logo' },
  '336': { src: C6, alt: 'C6 logo' },
  '371': { src: Warren, alt: 'Warren logo' },
};

const bankDataMonochromeMap: BankDataMap = {
  '033': { src: SantanderMonochrome, alt: 'Santander logo' },
  '341': { src: ItauMonochrome, alt: 'Itaú logo' },
  '208': { src: BTGMonochrome, alt: 'BTG logo' },
};

function bankData(routingNumber: string, bw: boolean) {
  return bw
    ? bankDataMonochromeMap[routingNumber] ?? bankDataMap[routingNumber]
    : bankDataMap[routingNumber] ?? { icon: BankIconSvg, alt: 'Banco logo' };
}

function BankIcon({
  routingNumber,
  width = 32,
  height = 32,
  monochrome = false,
  className,
}: Props) {
  const style = { width, height };

  if (!routingNumber) {
    return (
      <div className={styles.iconWrapper} style={style}>
        <WalletIcon
          aria-label="Conta não bancarizada logo"
          className={cn(styles.icon, className)}
        />
      </div>
    );
  }

  const { src, alt, icon: Icon } = bankData(routingNumber, monochrome);

  if (Icon) {
    return (
      <div className={cn(styles.iconWrapper, className)} style={style}>
        <Icon aria-label={alt} className={styles.icon} />
      </div>
    );
  }

  return (
    <img
      style={style}
      src={src}
      alt={alt}
      className={cn(styles.img, className)}
    />
  );
}

export default BankIcon;
