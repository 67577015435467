import classNames from 'classnames';
import { Stack } from '@mui/material';

import LegendItem from './LegendItem';
import styles from './Legends.module.scss';

type Props = {
  isEmpty: boolean;
};

export default function Legends({ isEmpty }: Props) {
  return (
    <Stack
      direction="row"
      gap={6}
      className={classNames(styles.legend, {
        [styles.emptyLegend]: isEmpty,
      })}
    >
      <LegendItem
        isEmpty={isEmpty}
        labelText="Saldo"
        iconProps={{
          stroke: 'black',
          className: styles.resultAmountlegendIcon,
        }}
      />

      <LegendItem
        isEmpty={isEmpty}
        labelText="Entradas"
        iconProps={{
          className: styles.creditAmountlegendIcon,
        }}
      />

      <LegendItem
        isEmpty={isEmpty}
        labelText="Saídas"
        iconProps={{
          className: styles.debitAmountlegendIcon,
        }}
      />
    </Stack>
  );
}
