import type {
  GridColDef,
  GridValidRowModel,
  GridValueSetter,
} from '@mui/x-data-grid-premium';
import { getGridStringOperators } from '@mui/x-data-grid-premium';

import { notEqualFilterOperator } from './filters';

type Props<T extends GridValidRowModel> = {
  columnProps: GridColDef<T>;
};

export function textColumn<T extends GridValidRowModel>({
  columnProps,
}: Props<T>): GridColDef<T> {
  return {
    filterOperators: [...getGridStringOperators(), notEqualFilterOperator],
    type: 'string',
    valueSetter: (value: GridValueSetter, row) => {
      return {
        ...row,
        [columnProps.field]: value,
        updated: { [columnProps.field]: value },
      };
    },
    ...columnProps,
  };
}
