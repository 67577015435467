import { useMemo } from 'react';

import type { TransitionProps } from '@mui/material/transitions';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material';
import _debounce from 'lodash/debounce';
import { CloseIcon } from 'src/mui/_icons';
import type { PortalProps } from 'src/ui/PortalProvider';
import { Table } from 'src/mui/_scss';
import { checkboxColumn } from 'src/mui/_scss/Table';
import type { GridColDef } from '@mui/x-data-grid-premium';

import { format } from 'src/libs/finbits/Date';
import { useGetDeletedEntries } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { amountColumn } from 'src/features/entries/EntriesDataGrid/columns/amountColumn/amountColumn';
import { dateColumn } from 'src/features/entries/EntriesDataGrid/columns/dateColumn/dateColumn';
import { descriptionColumn } from 'src/features/entries/EntriesDataGrid/columns/descriptionColumn/descriptionColumn';
import { accountColumn } from 'src/features/entries/EntriesDataGrid/columns/accountColumn/accountColumn';
import { currentSituationColumn } from 'src/features/entries/EntriesDataGrid/columns/currentSituationColumn/currentSituationColumn';
import { categoryColumn } from 'src/features/entries/EntriesDataGrid/columns/categoryColumn/categoryColumn';
import { contactColumn } from 'src/features/entries/EntriesDataGrid/columns/contactColumn/contactColumn';
import { relevantdateColumn } from 'src/features/entries/EntriesDataGrid/columns/relevantDateColumn/relevantDateColumn';
import { classificationsColumn } from 'src/features/entries/EntriesDataGrid/columns/classificationColumn/classificationColumn';
import { amountTypeColumn } from 'src/features/entries/EntriesDataGrid/columns/amountTypeColumn/amountTypeColumn';
import { attachmentColumn } from 'src/features/entries/EntriesDataGrid/columns/attachmentColumn/attachmentColumn';
import ToolbarQuickActions from 'src/features/bills-to-pay/DeletedBillModal/GridToolBar/ToolbarQuickActions';
import ContextMenuRowActions from 'src/features/entries/EntriesDataGrid/ContextMenuRowActions/ContextMenuRowActions';
import useEntriesParams from 'src/features/entries/useEntriesParams';

import ToolbarSelectedActions from './GridToolBar/ToolbarSelectedActions';
import { RowActions } from './RowAction';
import styles from './DeletedBillModal.module.scss';

export default function DeletedBillModal({
  onClose,
  open = true,
  onExit,
}: PortalProps) {
  const { organizationId, companyId } = useCompanyParams();

  const { selectedAccountIds, selectedDateRange } = useEntriesParams();

  const { classifications, isLoading: isLoadingClassifications } =
    useClassifications({ companyId, organizationId });

  const { data, isLoading } = useGetDeletedEntries(
    {
      organizationId,
      companyId,
      accountsIds: selectedAccountIds,
      startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
      endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
    },
    { enabled: Boolean(selectedAccountIds.length) }
  );

  const columns: GridColDef[] = useMemo(() => {
    return [
      checkboxColumn(),
      dateColumn({ editable: false, headerName: 'Data de pagamento' }),
      descriptionColumn({ editable: false }),
      accountColumn({ editable: false }),
      currentSituationColumn(),
      categoryColumn({ editable: false }),
      contactColumn({ editable: false }),
      relevantdateColumn({ editable: false }),
      ...classificationsColumn({
        classifications,
        columnProps: { editable: false },
      }),
      amountColumn({ editable: false }),
      amountTypeColumn({ filterable: false }),
      attachmentColumn(),
      {
        field: 'Ações',
        type: 'actions',
        editable: false,
        getActions: RowActions,
        align: 'right' as any,
        resizable: false,
        hideable: false,
        maxWidth: 50,
        flex: 1,
        groupable: false,
        aggregable: false,
        disableExport: true,
      },
    ];
  }, [classifications]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
      maxWidth="xxl"
      fullWidth
      aria-labelledby="modal-title"
    >
      <DialogTitle id="modal-title" variant="text-lg" fontWeight="600">
        Pagamentos excluídos
      </DialogTitle>
      <IconButton
        aria-label="Fechar modal"
        onClick={onClose}
        className={styles.closeIcon}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers className={styles.content}>
        <Table
          showQuickFilter
          rows={data?.entries ?? []}
          columns={columns}
          isLoading={isLoading || isLoadingClassifications}
          checkboxSelection
          CustomContextMenuOptions={ContextMenuRowActions}
          GridToolBarSlots={{
            QuickActions: ToolbarQuickActions,
            SelectedActions: ToolbarSelectedActions,
            selectedText: [
              'lançamento selecionado',
              'lançamentos selecionados',
            ],
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
