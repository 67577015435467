import { Fade, IconButton, Stack, Tooltip } from '@mui/material';
import {
  FlipBackwardIcon,
  IntersectSquareIcon,
  PlusIcon,
  TrashIcon,
} from 'src/mui/_icons';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

import InboxItemMenuWithIconButton from 'src/features/inbox-items/InboxItemMenu/InboxItemMenuWithIconButton';
import useInboxItemActions from 'src/features/inbox-items/useInboxItemActions';

type Props = {
  visible?: boolean;
  inboxItem: NewInboxItem;
};

export default function QuickActions({ visible = true, inboxItem }: Props) {
  const {
    onCreateEntry,
    onConciliation,
    onRejection,
    onRestore,
    isAllowedActions,
    isAllowedCreateBill,
    isAllowedUpdateBill,
    isAllowedRejectManyInboxItems,
    isAllowedRestoreManyInboxItems,
  } = useInboxItemActions({
    inboxItem: inboxItem,
  });

  const isPending = inboxItem?.status === 'pending';
  const isRejected = inboxItem?.status === 'rejected';

  if (!isAllowedActions) {
    return null;
  }

  return (
    <Fade in={visible}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        onClick={(e) => e.stopPropagation()}
      >
        {isPending && (
          <>
            {isAllowedCreateBill && (
              <Tooltip title="Criar lançamento">
                <IconButton
                  size="small"
                  aria-label="Criar lançamento"
                  onClick={onCreateEntry}
                >
                  <PlusIcon />
                </IconButton>
              </Tooltip>
            )}
            {isAllowedUpdateBill && (
              <Tooltip title="Vincular à lançamento">
                <IconButton
                  size="small"
                  aria-label="Vincular à lançamento"
                  onClick={onConciliation}
                >
                  <IntersectSquareIcon />
                </IconButton>
              </Tooltip>
            )}
            {isAllowedRejectManyInboxItems && (
              <Tooltip title="Rejeitar">
                <IconButton
                  size="small"
                  aria-label="Rejeitar"
                  onClick={onRejection}
                >
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}

        {isRejected && isAllowedRestoreManyInboxItems && (
          <Tooltip title="Restaurar">
            <IconButton size="small" aria-label="Restaurar" onClick={onRestore}>
              <FlipBackwardIcon />
            </IconButton>
          </Tooltip>
        )}

        <InboxItemMenuWithIconButton size="small" inboxItem={inboxItem} />
      </Stack>
    </Fade>
  );
}
