import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import isArray from 'lodash/isArray';

import { TextField } from 'src/mui';

import { emailValidator } from 'src/libs/finbits/Email';
import type { ErrorsObject } from 'src/libs/finbits/Form';
import { errorsToFormField } from 'src/libs/finbits/Form';
import { cellphoneMask } from 'src/libs/finbits/Organization/PhoneNumber';
import type { UserInfo } from 'src/libs/finbits/Organization/types';
import { UserInfoFormResolver } from 'src/libs/finbits/Organization/types';

type Props = {
  onSubmit: (user: UserInfo) => void;
  setIsFormEdited?: (value: boolean) => void;
  initialValues?: UserInfo;
  submitErrors?: ErrorsObject;
};

export default function UserForm({
  submitErrors,
  initialValues,
  onSubmit,
  setIsFormEdited,
}: Props) {
  const [searchParams] = useSearchParams();
  const initialEmail = searchParams.get('email');

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
  } = useForm<UserInfo>({
    defaultValues: {
      name: initialValues?.name ?? '',
      email: initialEmail ?? initialValues?.email ?? '',
      phone: {
        number: initialValues?.phone.number ?? '',
      },
      password: initialValues?.password ?? '',
    },
    resolver: zodResolver(UserInfoFormResolver),
  });

  useEffect(() => {
    setIsFormEdited && setIsFormEdited(isDirty);
  }, [isDirty, setIsFormEdited]);

  useEffect(() => {
    const errors = submitErrors && errorsToFormField(submitErrors);
    errors?.map(({ name, errors }) => {
      const field = isArray(name) ? name.join('.') : name;
      const message = errors.join(', ');
      return setError(field as any, { message });
    });
  }, [setError, submitErrors]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.name}
            helperText={errors.name?.message}
            label="Nome"
            margin="normal"
            placeholder="Insira seu nome"
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.email}
            helperText={errors.email?.message}
            label="E-mail"
            margin="normal"
            placeholder="Insira seu e-mail"
            disabled={!!initialEmail && emailValidator(initialEmail)}
          />
        )}
      />
      <Controller
        name="phone.number"
        control={control}
        render={({ field }) => (
          <TextField.Masked
            {...field}
            error={!!errors.phone?.number}
            helperText={errors.phone?.number?.message}
            label="Celular"
            margin="normal"
            mask={[cellphoneMask]}
            placeholder="(11) 90000-0000"
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField.Password
            {...field}
            error={!!errors.password}
            helperText={errors.password?.message}
            label="Senha"
            margin="normal"
            placeholder="••••••••"
            type="password"
          />
        )}
      />

      <Button
        color="primary"
        fullWidth
        sx={{ mt: 8 }}
        type="submit"
        variant="contained"
        size="large"
      >
        Continuar
      </Button>
    </Box>
  );
}
